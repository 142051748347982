import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Image, Offcanvas, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import TitleDashboard from '../Titles/TitleDashboard'
import '@assets/css/components/SalaEntretenimiento/style.css'
import validarCredito from '@helpers/creditoEntretenimiento/validarCredito'
import UserContext from '@context/User/UserContext'
import Joyride from 'react-joyride'
import spanishOptionsJoyRide from '@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import Memorama from './memorama/Memorama'
import App from './memorama/Memorama'
import DynamicBreadcrumb from '../DynamicBreadcrumb/DynamicBreadcrumb'
import { FaGamepad } from 'react-icons/fa'
import { isMobile } from '@helpers/validaciones/isMobile'
import { AiOutlineRotateLeft } from "react-icons/ai";
import useWindowResize from '@customHooks/useWindowResize'
import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp'
import { registrarUsoJuegoSalaEntretenimiento } from '@helpers/httpRequests/httpRequestsSalaEntretenimiento/httpRequestsSalaEntretenimiento'


const SalaEntretenimiento = ({ setFichaUsada, fichaUsada, setFichaDisponible, fichaDisponible, time}) => {

    const joyrideRef2 = useRef(null);

    const breadcrumbItemsInitialState = [{ title: "Sala de entretenimiento", onClick: () => { } }];

    const [tourSteps2, setTourSteps] = useState([
        {
            target: ".question",
            content: "Libera el estrés y despeja tu mente con la sala de entretenimiento que click+ tiene para ti.",
            disableBeacon: true,
        },

        {
            target: ".seleccionar-juego",
            content: "En este apartado encontrarás la lista de juegos disponibles.",
            disableBeacon: true,
        },


        {
            target: ".question",
            content: "Al ingresar a la sala cuentas con 15 minutos de juego y al finalizar se cerrará la sala, vuelve luego de una hora y tendrás otros 15 minutos más de juego.",
            disableBeacon: true,
        },


        {
            target: ".question",
            content: "Eso sería todo, recuerda siempre darte un momento para relajarte y así conseguirás un mejor desempeño durante tu aprendizaje.",
            disableBeacon: true,
        },

    ]);

    const [runTour, setRunTour] = useState(false);

    const [nombreJuego, setNombreJuego] = useState('memo')

    const [juegoSeleccionado, setJuegoSeleccionado] = useState(<App />);

    //Cuenta para tomar el tiempo de cada juego en la sala de entretenimiento.
	const [tiempoUsoJuego, setTiempoUsoJuego] = useState(0);


    const { contextValue, setContextValue } = useContext(UserContext);

    const { usuarioLogeado } = contextValue;

    const { idPerfil } = usuarioLogeado;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const datosJuego = localStorage.getItem('datosUsoJuego');
    
    const [datosUsoJuego, setDatosUsoJuego] = useState(datosJuego ? JSON.parse(datosJuego) : [])


    const [timerUsoJuego, setTimerUsoJuego] = useState(null);
    const [estatus, setEstatus] = useState(false);


    const tomarTiempoJuego = (estatus) => {
         // Limpiar el intervalo anterior si existe
            clearInterval(timerUsoJuego);
            setTiempoUsoJuego(0)

        // Iniciar un nuevo intervalo
        const nuevoTimerUsoJuego = setInterval(() => {
            setTiempoUsoJuego((prevTime) => prevTime + 1);
        }, 1000);

        // Almacenar el ID del nuevo intervalo en el estado
        setTimerUsoJuego(nuevoTimerUsoJuego);
        
    }

    const handleGame = async({ currentTarget }) => {

        datosUsoJuego.push({
            nombre: nombreJuego,
            tiempoJuego: tiempoUsoJuego,
            fechaRegistro: getUnixTimestamp()
        });

        tomarTiempoJuego();

        const { name } = currentTarget;

        setNombreJuego(name)

        switch (name) {
            case "dino":
                setJuegoSeleccionado("https://globalclickmexico.com/games/dino_game")
                setShow(false)
                break;

            case "snake":
                setJuegoSeleccionado("https://globalclickmexico.com/games/snake_game")
                setShow(false)
                break;

            case "memo":
                setJuegoSeleccionado(<App />)
                setShow(false)
                break;


            default:
                break;
        }
        localStorage.setItem("datosUsoJuego", JSON.stringify(datosUsoJuego));
        


    }



    const usarFicha = () => {
        setFichaUsada(true);
        setFichaDisponible(false);

        tomarTiempoJuego();

    }


    const handleStartTour = () => {
        setRunTour(true);
    }

    const [showRotateIcon, setShowRotateIcon] = useState(false);

    const {
		size: { width },
	} = useWindowResize();

    useEffect(() => {
        
        if (isMobile.any()) {
            setShowRotateIcon(true)
        }
        const validarCreditoUsuario = async () => {
            try {
                const tieneFicha = await validarCredito(idPerfil)
                if (tieneFicha) {

                    setFichaDisponible(true);
                } else {

                    setFichaDisponible(false);
                }

            } catch (error) {
                console.log(error)
            }
        }
        validarCreditoUsuario();

        
    }, [])


    useEffect(() => {
        const validarCreditoUsuario = async () => {
            try {
                const tieneFicha = await validarCredito(idPerfil)
                if (tieneFicha) {

                    setFichaDisponible(true);
                } else {

                    setFichaDisponible(false);
                }

            } catch (error) {
                console.log(error)
            }
        }
        validarCreditoUsuario();
    }, [fichaUsada])


    // useEffect(() => {
        
    //     if(fichaDisponible === false) {
    //         const registrarUso = async() =>{
    //             try {
    //                 await registrarUsoJuegoSalaEntretenimiento({
    //                     infoJuegos: datosUsoJuego
    //                 });
    //             } catch (error) {
    //                 console.log(error.message);
    //             }

    //         }
    //         registrarUso();
    //     }

    // }, [fichaDisponible])

    
    


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
      };



    return (
        <Container fluid>


            <Offcanvas placement='end' show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h2>Juegos disponibles</h2> 
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col className={`contenedor-juegos`}>
                        {fichaDisponible ? (
                           <>
                             <Row className='mt-4'>
                            <Col className="card-juego">
                                <picture width="100%" height="100%">
                                    <source
                                        srcSet={`${require(`@assets/images/memoramaGame.webp`)}`}
                                    />
                                    <img
                                        loading="lazy"
                                        onClick={handleGame} name="memo" className='rounded-4 pointer img-fluid'
                                        src={`${require(`@assets/images/memoramaGame.png`)}`} // Aquí va la URL de la imagen de fondo
                                        alt="Imagen de fondo"
                                    />
                                </picture>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className="card-juego">
                                <picture width="100%" height="100%">
                                    <source
                                        srcSet={`${require(`@assets/images/snakeGame.webp`)}`}
                                    />
                                    <img
                                        loading="lazy"
                                        onClick={handleGame} name="snake" className='rounded-4 pointer img-fluid'
                                        src={`${require(`@assets/images/snakeGame.png`)}`} // Aquí va la URL de la imagen de fondo
                                        alt="Imagen de fondo"
                                    />
                                </picture>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className="card-juego">

                                <picture width="100%" height="100%">
                                    <source
                                        srcSet={`${require(`@assets/images/dinoGame.webp`)}`}
                                    />
                                    <img
                                        loading="lazy"
                                        onClick={handleGame} name="dino" className='rounded-4 pointer img-fluid'
                                        src={`${require(`@assets/images/dinoGame.png`)}`} // Aquí va la URL de la imagen de fondo
                                        alt="Imagen de fondo"
                                    />
                                </picture>
                            </Col>
                        </Row>
                           </>
                        ): (
                            <Col className='col-12 text-center mt-5' style={{ height: "auto" }}>
                                <h3>Se ha terminado el tiempo.</h3>
                                <picture width="100%" height="100%">
                                    <source
                                        srcSet={`${require(`@assets/images/cerrado.webp`)}`}
                                    />
                                    <img
                                        loading="lazy"
                                        className='m-4' src={require(`@assets/images/cerrado.png`)}
                                        width="200"
                                        alt="Imagen de fondo"
                                    />
                                </picture>

                                <p>Podrás seleccionar un juego hasta que se abra de nuevo la sala.</p>
                            </Col>
                        )}
                    </Col>
                </Offcanvas.Body>
            </Offcanvas>

            <Joyride
                locale={spanishOptionsJoyRide}
                ref={joyrideRef2}
                steps={tourSteps2}
                run={runTour}
                hideCloseButton
                showSkipButton
                continuous={true}
                disableOverlayClose={false}
                disableCloseOnEsc={false}
                disableScrolling={false}
                scrollToFirstStep={false}
                scrollOffset={100}
                callback={(data) => {
                    if (data.status === "finished" || data.status === "skipped") {
                        setRunTour(false);
                    }

                }}
                styles={{
                    options: {
                        primaryColor: "#ff9900",
                        textColor: "#00152A",
                        width: 900,
                        zIndex: 1000,
                    },
                }}
            />
            <Row className='d-flex align-items-center justify-content-between mt-2'>
                <Col className="col-auto ">
                    <DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
                </Col>



                <Col className={`col-auto text-center mx-3`}>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={<Tooltip>Conoce la Sala de Entretenimiento</Tooltip>}
                    >
                        <span>
                            <FontAwesomeIcon
                                className=" pointer question"
                                onClick={handleStartTour}
                                style={{ fontSize: "2rem", color: "black" }}
                                icon={faQuestion}
                            />
                        </span>
                    </OverlayTrigger>



                </Col>
                {fichaUsada ? (
                     <Col className='col-12 '>
                        <Button variant="primary" onClick={handleShow} className="mx-2 seleccionar-juego">
                            Seleccionar otro juego
                        </Button>
    
                        {fichaUsada === true && (
                            <span>Tiempo de juego restante: <b>{formatTime(time)}</b></span>
                        )}
    
                    </Col>
                ): null}

                {(width <= 1024 && nombreJuego === "snake" && fichaUsada === true) && (
                    <Col className='col-12 mt-3  text-center' style={{ color: "grey" }}>
                        <span style={{ fontSize: "3.5rem" }}><AiOutlineRotateLeft /></span>
                        <p>Rota tu dispositivo para una mejor experiencia</p>
                    </Col>
                )}
            </Row>



            <Row className='justify-content-center mt-2 mb-5'>

                {(fichaDisponible === true) ? (
                    fichaUsada === false ? (
                        <Col className='col-12 text-center mt-5'>
                            <h3>Es necesario darse un descanso luego de estudiar por mucho tiempo, ya se encuentra abierta la sala de entretenimiento.</h3>

                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>Entrar a la sala de juegos</Tooltip>}
                            >
                                <picture width="100%" height="100%">
                                    <source
                                        srcSet={`${require(`@assets/images/signo-abierto.webp`)}`}
                                    />
                                    <img
                                        loading="lazy"
                                        onClick={usarFicha} className='m-4 pointer inserte-moneda' src={require(`@assets/images/signo-abierto.png`)}
                                        width="200"
                                        alt="Imagen de fondo"
                                    />
                                </picture>

                            </OverlayTrigger>
                            <p>
                                <h3>Da click en el letrero para comenzar.</h3>
                            </p>


                            <p>Una vez que entres tienes 15 minutos de juego, al terminar, la sala volverá a abrir luego de una hora</p>
                        </Col>
                    ) : (
                        nombreJuego !== "memo" ? (
                            <Col className='col-12' style={{ height: "100vh" }}>
                                <iframe className='w-100 h-100' src={juegoSeleccionado} title='Dino game' />
                            </Col>
                        ) : (
                            <Col className='col-12' style={{ position: "relative" }} >
                                {juegoSeleccionado}
                            </Col>
                        )
                    )
                ) : (
                    <Col className='col-12 text-center mt-5' style={{ height: "auto" }}>
                        <h3>Se ha terminado el tiempo.</h3>
                        <picture width="100%" height="100%">
                            <source
                                srcSet={`${require(`@assets/images/cerrado.webp`)}`}
                            />
                            <img
                                loading="lazy"
                                className='m-4' src={require(`@assets/images/cerrado.png`)}
                                width="200"
                                alt="Imagen de fondo"
                            />
                        </picture>

                        <p>Regresa dentro de una hora volver a abrir la sala de entretenimiento.</p>
                    </Col>

                )}
            </Row>
           
           

        </Container>
    )
}

export default SalaEntretenimiento