import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const ActualizacionAppMovil = () => {
  return (
    <Row>
      <Col>
        <TitleDashboard title={"Actualización de app móvil"} />
        <div className="mt-4">
          <p>Para el proceso de actualización de la app móvil pueden surgir diferentes escenarios, por lo que es importante tener en cuenta los siguientes pasos en caso de:</p>

          <p>Perdida de claves para la Keystore android</p>
          <p>En caso de que se extravíe o no se recuerden las claves de la llave de carga keystore de la app móvil android es necesario seguir los siguientes pasos: </p>
          <ul>
            <li>1. Ingresar a la consola de desarrolladores de Google Play</li>
            <li>2. Una vez que se inicie la sesión, en el apartado de configuración de la categoría PRUEBA Y LANZA del menú lateral del panel, seleccionar la opción FIRMA DE APP.</li>
            <li>3. En la sección de "Firma de app" dirigirse a la sección de CERTIFICADO DE CLAVE DE CARGA.</li>
            <li>4. Dar click en el enlace que dice COMO SOLICITAR QUE SE RESTABLEZCA LA CLAVE DE CARGA.</li>
            <li>5. Llenar el formulario y, para poder continuar hay que dirigirse a Android studio para generar un nuevo archivo keystore, el cual se subirá a la consola en formato .pem .</li>
            <li>Se pueden seguir las instrucciones que se mencionan en google play console para la generación del archivo .pem pero se recomienda usar el programa <b>keystore explorer</b></li>
            <li>Descargalo aquí: <a href='https://keystore-explorer.org/' target='_blank' rel="noreferrer">https://keystore-explorer.org/</a></li>
            <li>Antes de utilizarlo hay que generar una nueva Keystore desde Android Studio guardando en un lugar seguro toda la información para evitar su perdida.</li>
            <li>Una vez generada la nueva Keystore abrimos el archivo con con la keystore password que previamente se configuró.</li>
            
            <div className='text-center my-4'>
              <img src='https://globalclickmexico.com/img/nuevosRecursos/abrirKeystore.webp' alt='Abrir keystore' width="500"/>
            </div>

            <li>Se mostrará el contenido del archivo:</li>

            <div className='text-center my-4'>
              <img src='https://globalclickmexico.com/img/nuevosRecursos/verContenidoKeystore.webp' alt='Abrir keystore' width="500"/>
            </div>

            <li>Seleccionamos el archivo dentro de la keystore y nos mostrará la opción para generar el archivo .pem.</li>

            <div className='text-center my-4'>
              <img src='https://globalclickmexico.com/img/nuevosRecursos/generarPem.webp' alt='Abrir keystore' width="500"/>
            </div>

            <li>Seguimos las intrucciones para generar el archivo .pem y lo subimos en la google play console para enviarla y terminar con el proceso, una vez entregado te llegará un correo de confirmación y la fecha en la que estará activa la nueva clave de carga.</li>

          </ul>
        </div>

      </Col>
    </Row>
  )
}

export default ActualizacionAppMovil