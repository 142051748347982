import React from "react";
import { Container, Row, Col, Tab, Nav, Accordion } from "react-bootstrap";

import "@assets/css/components/DashboardFAQ/style.css";
import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { Animated } from "react-animated-css";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export const DashboardFAQ = ({ showScrollButton }) => {
	const navItems = [
		{
			eventKey: "plataforma",
			title: "Uso de la plataforma",
		},
		{
			eventKey: "grupos",
			title: "Grupos Empresariales",
		},
		{
			eventKey: "certificaciones",
			title: "Certificaciones",
		},
		{
			eventKey: "asesoria",
			title: "Asesoría personalizada",
		},
		{
			eventKey: "otros",
			title: "Otros",
		},
	];

	const tabPaneItems = {
		plataforma: [
			{
				headerText: "¿Cómo registro a mi segundo usuario?",
				content: (
					<div>
						<p>
							Si eres titular de una licencia <b>Click+ Premium</b>, encontrarás el formulario de registro en la esquina superior derecha al dar clic sobre tu nombre en la opción{" "}
							<b>"Registrar 2do usuario"</b>. Al finalizar, tu compañero recibirá un correo de confirmación y deberás compartir con él tus credenciales de acceso. Recuerda que la información de acceso
							es compartida, sin embargo, dentro de la cuenta encontrás un perfil para ti y tu segundo usuario.
						</p>
					</div>
				),
			},
			{
				headerText: "¿Por dónde empezar?",
				content: (
					<div>
						<p>Toma una ruta de aprendizaje, la cual tiene un listado de lecciones que te ayudarán a desarrollar una habilidad ofimática en específico.</p>
						<p>
							Evaluar tus conocimientos en Excel y Power BI, con un examen diagnóstico. Al finalizar, obtendrás una retroalimentación con el nivel de Excel/Power BI, en el que te encuentras y una
							serie de lecciones que te ayudarán a mejorar tus habilidades.
						</p>
						<p>
							Inicia el curso de tu interés. En la página principal encontrarás un listado de categorías de estudio, que las conforman los mejores cursos de ofimática que te ayudarán en tu crecimiento
							profesional.
						</p>
					</div>
				),
			},
			{
				headerText: "¿Qué temas trata el examen diagnóstico?",
				content:
					"Excel y Power Business Intelligence (Power BI), al finalizar recibirás retroalimentación con lecciones sugeridas de acuerdo a tu nivel de conocimiento, para reforzar y aprender nuevas habilidades.",
			},
			{
				headerText: "Material visto en capacitación",
				content: "Si algo no quedó claro, aquí encontrarás las lecciones de apoyo, utilizadas en tu capacitación empresarial impartida por un instructor. ",
			},
			{
				headerText: "¿Qué es una ruta de aprendizaje?",
				content: "Es una lista de reproducción, de lecciones ordenadas de acuerdo al nivel de conocimientos que necesitas para adquirir cierta habilidad ofimática en específico.",
			},
			{
				headerText: "¿Qué son los consejos rápidos?",
				content: "Son atajos/comandos de distintos programas que te servirán para ser cada vez más ágil en el manejo de las aplicaciones ofimáticas.",
			},
			{
				headerText: "¿Cómo utilizar el Foro de Ayuda?",
				content:
					"Puedes agregar una publicación, donde redactes alguna duda relacionada a los temas vistos en los diferentes cursos. Un experto y la comunidad en general responderá a tu pregunta con el fin de lograr un conocimiento colectivo y una interacción entre los estudiantes.",
			},
			{
				headerText: "¿Cómo utilizar el Aula Virtual?",
				content:
					"Una vez que recibas notificación de Nuevo taller en vivo, tendrás la información para acceder y tomar provecho del tema expuesto. Cada transmisión se guardará en esa misma sección y la podrás volver a ver las veces que quieras.",
			},
			{
				headerText: "¿Cómo posicionarme en el Ranking de un curso?",
				content: "Al término de la evaluación final de cada curso, tu calificación quedará registrada y de acuerdo a tu resultado estarás posicionado en el Ranking, entre más alta sea, mejor.",
			},
			{
				headerText: "¿Cómo marcar una lección como completada?",
				content: "Cada lección cuenta con una casilla que podrás marcar cuando completada, cuando te sientas listo para avanzar a la siguiente lección, así llevarás un mejor control a tu ritmo.",
			},
			{
				headerText: "¿Cómo puedo saber mi avance general?",
				content: "En la página principal encontrarás un acceso directo (ícono de gráficos de barra) o puedes ingresar desde la opción del menú lateral “Mis avances”.",
			},
			{
				headerText: "¿Cómo cambio mi contraseña?",
				content:
					'En el menú desplegable de usuario, elige "Mi perfil" y selecciona la pestaña de “Cambio de contraseña”, recibirás un código de verificación en tu correo que deberás insertar para confirmar tu identidad.',
			},
			{
				headerText: "¿Puedo ver las lecciones desde mi dispositivo móvil?",
				content:
					"Sí, las actividades pueden visualizarse en cualquier dispositivo, sin embargo, te recomendamos trabajar en dispositivo de escritorio, así podrás llevar tus conocimientos a la práctica usando los comandos necesarios.",
			},
		],
		grupos: [
			{
				headerText: "¿Cómo puedo agregar un nuevo integrante a mi grupo?",
				content:
					"Para realizar el registro de un integrante a tu grupo empresarial, deberás ingresar a Foro Empresarial > Colaboradores y hacer clic en el botón 'Nuevo colaborador'. Una vez dado de alta, el nuevo integrante de tu equipo podrá ingresar a la plataforma con su usuario y contraseña asignados previamente. ",
			},
			{
				headerText: "¿Para qué sirve el foro empresarial?",
				content:
					"Es un espacio exclusivo para un grupo empresarial, en el que todos los integrantes de un grupo visualizan el contenido personalizado, creado con base a las necesidades y solicitudes de la empresa, y en el caso del Asesor (Titular de la cuenta) puede ver el avance individual de cada integrante.",
			},
			{
				headerText: "¿Qué es una lección empresarial?",
				content:
					"Es una lección interactiva totalmente personalizada, dirigida a tu grupo de colaboradores, que sirve como temario de capacitación para tu empresa. Puedes solicitarlas en la sección Grupo Empresarial > Lecciones.",
			},
			{
				headerText: "¿Qué es una publicación empresarial?",
				content:
					"A diferencia de la lección empresarial, una publicación es una nota breve que puede realizar el asesor, consta de una descripción y una plantilla auxiliar que los colaboradores de un grupo pueden ver y descargar. Es útil para fines informativos internos de la empresa.",
			},
		],
		certificaciones: [
			{
				headerText: "¿Cómo obtengo mi certificado de finalización de curso?",
				content:
					"Al final de las lecciones de cada curso encontrarás un examen final, el cual, deberá ser aprobado con un mínimo de 85% de calificación, si cumple con esta condición, entonces podrás emitir tu certificado de forma automática.",
			},
			{
				headerText: "¿Qué necesito para obtener mi certificación MOS?",
				content: "Revisa la sección exclusiva del Curso Propedéutico MOS, ahí encontrarás toda la información a detalle para poder adquirir tu certificación respaldada por Microsoft.",
			},
		],
		asesoria: [
			{
				headerText: "¿Cómo solicito una asesoría personalizada?",
				content: (
					<>
						<p>Agenda una asesoría personalizada para resolver cualquier duda relacionada a temas de Office, en prácticas o proyectos personales.</p>
						<p>
							Solicítala a través del Módulo de Agendas de Asesorías disponible desde tu plataforma Click+.
						</p>
					</>
				),
			},
		],
		otros: [
			{
				headerText: "¿No encontraste una respuesta?",
				content: (
					<>
						Contacta a uno de nuestros asesores a través del chat en línea, puedes encontrar el botón de color azul en la esquina inferior derecha.
						<br />
						Teléfono de oficinas: 477 390 83 01, ext. 101 ó 102
						<br /> 
						Horarios: Lunes a Viernes: 8:30 a.m. a 8:00 p.m. y Sábados: 8:00 a.m. a 2:00 p.m <br />
					</>
				),
			},
		],
	};

	return (
		<Container fluid className="p-0 mb-3">
			{showScrollButton && (
				<Animated animationIn="fadeIn" isVisible={true} style={{ zIndex: "9999", position: "absolute" }}>
					<span
						className={`rounded-circle scrollTop text-center d-flex`}
						style={{
							height: "50px",
							width: "50px",
							fontSize: "2rem",
							backgroundColor: "#F59E0B",
							position: "fixed",
							bottom: "70px",
							right: "40px",
						}}>
						<Link className="pointer" activeClass="active" to="scrollTopElement" spy={true} smooth={true} duration={800} containerId="scrollTop" style={{ color: "black", width: "100%" }}>
							<FontAwesomeIcon icon={faArrowUp} />
						</Link>
					</span>
				</Animated>
			)}
			<DynamicBreadcrumb arrayBreadcrumbs={[{ title: "Preguntas frecuentes", onClick: () => {} }]} />

			<Row className="mb-5">
				<Col>
					<TitleDashboard title={"Preguntas frecuentes"} />
				</Col>
			</Row>

			<Row>
				<Col>
					<Tab.Container id="left-tabs-example" defaultActiveKey="plataforma">
						<Container fluid>
							<Row>
								<Col sm={4}>
									<Nav variant="pills" className="flex-column" style={{ borderRadius: "10px" }}>
										{navItems.map((navItem, idx) => (
											<Nav.Item key={idx}>
												<Nav.Link eventKey={navItem.eventKey}>{navItem.title}</Nav.Link>
											</Nav.Item>
										))}
									</Nav>
								</Col>

								<Col sm={8}>
									<Tab.Content>
										{navItems.map(({ eventKey }, idx) => (
											<Tab.Pane eventKey={eventKey} key={idx}>
												<Accordion defaultActiveKey="0">
													{tabPaneItems[eventKey].map((tabPaneItem, idxTabPane) => (
														<Accordion.Item eventKey={idxTabPane} key={idxTabPane}>
															<Accordion.Header>
																<h6>{tabPaneItem.headerText}</h6>
															</Accordion.Header>

															<Accordion.Body>{tabPaneItem.content}</Accordion.Body>
														</Accordion.Item>
													))}
												</Accordion>

												{/* <Accordion multiple>
													{tabPaneItems[eventKey].map((tabPaneItem, idxTabPane) => (
														<AccordionTab header={<h6>{tabPaneItem.headerText}</h6>} className="pb-3" key={idxTabPane}>
															<> {tabPaneItem.content} </>
														</AccordionTab>
													))}
												</Accordion> */}
											</Tab.Pane>
										))}
									</Tab.Content>
								</Col>
							</Row>
						</Container>
					</Tab.Container>
				</Col>
			</Row>
		</Container>
	);
};
