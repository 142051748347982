import {
  Notificacion,
  NotificacionExtendida,
} from "@components/general/Alertas/Alertas";
import CapsLockTooltip from "@components/general/CapsLockTooltip/CapsLockTooltip";
import useCapsLockWatcher from "@customHooks/useCapsLockWatcher";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  validateExistenciaAlias,
  validateExistenciaCURP,
  validateExistenciaCorreo,
  validateExistenciaNombreUsuario,
} from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { addUsuarioEmpresarial } from "@helpers/httpRequests/httpRequestsUsuarios/registro/httpRequestsRegistroUsuarios";
import regExpStrings from "@helpers/regExpStrings/regExpStrings";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import validarRestriccionesContrasenia from "@helpers/validaciones/validarRestriccionesContrasenia/validarRestriccionesContrasenia";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

const NuevoUsuario = ({ folioGrupo, setEmpleadoRegistrado, idRegion }) => {
  const inputContrasenia = useRef(null);

  const isCapsLockOn = useCapsLockWatcher(inputContrasenia);

  const [validated, setValidated] = useState(false);

  const [showPasswords, setShowPasswords] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [formValues, setFormValues] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    nombreUsuario: "",
    curp: "",
    profesion: "",
    contrasenia: "",
    contraseniaConfirmada: "",
    alias: "",
  });

  const [validationForm, setValidationForm] = useState({
    nombre: false,
    apellidos: false,
    correo: false,
    curp: false,
    profesion: false,
    contrasenia: false,
    contraseniaConfirmada: false,
    validacionContrasenia: false,
    caracteresContrasenia: false,
    mayusculaContrasenia: false,
    numeroContrasenia: false,
    nombreUsuario: false,
    alias: false,
  });

  const handleOnChange = async ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]: name === "curp" ? value.toUpperCase() : value,
    });

    if (value === "") {
      return setValidationForm({
        ...validationForm,
        [name]: false,
      });
    }

    switch (name) {
      case "nombreUsuario":
        let nombreUsuarioValido = false;

        try {
          await validateExistenciaNombreUsuario(value);

          nombreUsuarioValido = true;
        } catch (error) {
          console.log("Error validando el nombre de usuario: ", error);

          nombreUsuarioValido = false;
        }

        setValidationForm({
          ...validationForm,
          nombreUsuario: nombreUsuarioValido,
        });
        break;

      case "alias":
        let aliasValido = false;

        try {
          await validateExistenciaAlias(value);

          aliasValido = true;
        } catch (error) {
          console.log("Error validando el alias: ", error);

          aliasValido = false;
        }

        setValidationForm({
          ...validationForm,
          alias: aliasValido,
        });

        break;

      case "curp":
        let curpValido = false;

        try {
          await validateExistenciaCURP(value);

          curpValido = true;
        } catch (error) {
          console.log("Error validando el CURP: ", error);

          curpValido = false;
        }

        setValidationForm({
          ...validationForm,
          curp: curpValido,
        });
        break;

      case "correo":
        let correoValido = false;

        if (regExpStrings.regexEmail(value)) {
          try {
            await validateExistenciaCorreo(value);

            correoValido = true;
          } catch (error) {
            console.log("Error validando correo: ", error);

            correoValido = false;
          }
        }

        setValidationForm({
          ...validationForm,
          correo: correoValido,
        });
        break;

      default:
        break;
    }
  };

  const handleShowPasswords = () => {
    setShowPasswords(!showPasswords);
  };

  const handleOnChangePasswords = async ({ target }) => {
    try {
      const { name, value } = target;

      // console.log(name, "", value);

      if (name === "contrasenia") {
        setFormValues({
          ...formValues,
          contrasenia: value,
        });

        const validacionesContrasenia = await validarRestriccionesContrasenia({
          contrasenia: value,
        });

        setValidationForm({
          ...validationForm,
          ...validacionesContrasenia,
        });
      } else if (name === "contraseniaConfirmada") {
        setFormValues({
          ...formValues,
          contraseniaConfirmada: value,
        });

        let contraseniasCoincidentes = false;

        if (value === formValues.contrasenia) {
          contraseniasCoincidentes = true;
        }

        setValidationForm({
          ...validationForm,
          contraseniaConfirmada: contraseniasCoincidentes,
        });
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const agregarNuevoColaborador = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setShowLoader(true);

    setValidated(true);

    try {
      const {
        nombreUsuario,
        nombre,
        apellidos,
        correo,
        puestoTrabajo,
        curp,
        contrasenia,
        contraseniaConfirmada,
        alias,
      } = formValues;

      if (
        nombre !== "" &&
        apellidos !== "" &&
        correo !== "" &&
        curp !== "" &&
        puestoTrabajo !== "" &&
        contrasenia !== "" &&
        contraseniaConfirmada !== "" &&
        alias !== "" &&
        nombreUsuario !== ""
      ) {
        //LOGICA PARA SUBIR LA INFORMACION

        const informacionEmpleado = {
          nombreUsuario,
          nombre,
          apellidos,
          correo,
          curp,
          profesion: puestoTrabajo,
          contrasenia: contraseniaConfirmada,
          folioContrato: parseInt(folioGrupo),
          alias,
          idRegion
        };

        const { message, code } = await addUsuarioEmpresarial(
          informacionEmpleado
        );

        // console.log(code);

        setEmpleadoRegistrado(true);

        setShowLoader(false);
        

        Notificacion("success", "Nuevo colaborador registrado en tu grupo");
      }
    } catch (error) {
      console.log(error);
      setShowLoader(false);

      // if (error.data === 'No se pueden registrar más empleados a este grupo, límite alcanzado') {

      // }

      NotificacionExtendida("error", error.message);
      // Notificacion("error", "Error al registrar al empleado");
    }
  };

  return (
    <Container className="text-start">
      <Row>
        <Col>
          <Form
            noValidate
            validated={validated}
            onSubmit={agregarNuevoColaborador}
          >
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    required
                    className={validationForm.nombre ? "is-invalid" : ""}
                    onChange={handleOnChange}
                    value={formValues.nombre}
                    name="nombre"
                    type="text"
                    placeholder="Nombre"
                  />

                  <Form.Control.Feedback type="invalid">
                    Este campo no puede ir vacío
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Apellidos</Form.Label>

                  <Form.Control
                    required
                    className={validationForm.apellidos ? "is-invalid" : ""}
                    onChange={handleOnChange}
                    value={formValues.apellidos}
                    name="apellidos"
                    type="text"
                    placeholder="Apellidos"
                  />

                  <Form.Control.Feedback type="invalid">
                    Este campo no puede ir vacío
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control
                    required
                    className={
                      validationForm.correo ? "is-valid" : "is-invalid"
                    }
                    onChange={handleOnChange}
                    value={formValues.correo}
                    name="correo"
                    type="email"
                    placeholder="Correo electrónico"
                  />

                  <Form.Control.Feedback type="invalid">
                    {formValues.correo === ""
                      ? "Ingresa un correo electrónico"
                      : "Este correo ya se encuentra registrado, verifica la información o contacta a Soporte Técnico"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>CURP</Form.Label>

                  <Form.Control
                    className={validationForm.curp ? "is-valid" : "is-invalid"}
                    name="curp"
                    type="text"
                    value={formValues.curp}
                    placeholder="Ingresa tu CURP"
                    maxLength="18"
                    onChange={handleOnChange}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formValues.curp === ""
                      ? "Ingresa tu CURP"
                      : "Este CURP ya se encuentra registrado, verifica la información o contacta a Soporte Técnico"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Puesto de trabajo o profesión</Form.Label>
                  <Form.Control
                    required
                    className={validationForm.profesion ? "is-invalid" : ""}
                    onChange={handleOnChange}
                    value={capitalizeString(formValues.profesion)}
                    name="profesion"
                    type="text"
                    placeholder="Puesto de trabajo"
                  />

                  <Form.Control.Feedback type="invalid">
                    Este campo no puede ir vacío
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre de usuario</Form.Label>

                  <Form.Control
                    className={
                      validationForm.nombreUsuario ? "is-valid" : "is-invalid"
                    }
                    type="text"
                    placeholder="Ingresa un nombre de usuario"
                    name="nombreUsuario"
                    value={formValues.nombreUsuario}
                    onChange={handleOnChange}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formValues.nombreUsuario === ""
                      ? "Ingresa un nombre de usuario"
                      : "Este nombre de usuario ya se encuentra en uso, por favor elige otro"}
                  </Form.Control.Feedback>

                  <Form.Text className="text-muted">
                    Este dato será necesario para cada vez que inicies sesión
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Alias de usuario</Form.Label>

                  <Form.Control
                    className={validationForm.alias ? "is-valid" : "is-invalid"}
                    type="text"
                    placeholder="Ingresa un alias de usuario"
                    name="alias"
                    value={formValues.alias}
                    onChange={handleOnChange}
                  />

                  <Form.Control.Feedback type="invalid">
                    {formValues.alias === ""
                      ? "Ingresa un alias"
                      : "Este alias ya se encuentra en uso, por favor elige otro"}
                  </Form.Control.Feedback>

                  <Form.Text className="text-muted">
                    Este será el nombre con el cual te verán otras personas en
                    la plataforma
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Contraseña</Form.Label>
                  <InputGroup hasValidation>
                      <InputGroup.Text id="inputGroupPrepend2" onClick={handleShowPasswords}>
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>

                    <Form.Control
                      required
                      className={
                        validationForm.contrasenia ? "is-valid" : "is-invalid"
                      }
                      onChange={handleOnChangePasswords}
                      value={formValues.contrasenia}
                      name="contrasenia"
                      type={showPasswords ? "text" : "password"}
                      autoComplete="off"
                      placeholder="Contraseña"
                      ref={inputContrasenia}
                    />

                    <Form.Control.Feedback type="invalid">
                      Genera una contraseña v&aacute;lida
                    </Form.Control.Feedback>

                    <CapsLockTooltip
                      input={inputContrasenia}
                      isCapsLockOn={isCapsLockOn}
                    />
                  </InputGroup>

                  <Form.Text id="passwordHelpBlock" muted>
                    La contraseña debe contener:
                    <ul>
                      <li
                        className={
                          validationForm.caracteresContrasenia
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        8 caracteres
                      </li>
                      <li
                        className={
                          validationForm.mayusculaContrasenia
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        1 mayúscula
                      </li>
                      <li
                        className={
                          validationForm.numeroContrasenia
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        1 número
                      </li>
                    </ul>
                    {/* La contraseña debe ser de al menos 8 caracteres, 1 mayúscula y 1 número */}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Confirmar contraseña</Form.Label>

                  <InputGroup hasValidation>
                   
                      <InputGroup.Text id="inputGroupPrepend2" onClick={handleShowPasswords}
                      className="pointer">
                        <FontAwesomeIcon icon={faEye} />
                      </InputGroup.Text>
                    

                    <Form.Control
                      required
                      className={`${
                        validationForm.contraseniaConfirmada
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      onChange={handleOnChangePasswords}
                      value={formValues.contraseniaConfirmada}
                      name="contraseniaConfirmada"
                      type={showPasswords ? "text" : "password"}
                      placeholder="Confirma la contraseña"
                    />

                    {/* {validationForm.contraseniaConfirmada === formValues.contraseniaConfirmada === "" && (
											<Form.Control.Feedback type='invalid'>Este campo no puede ir vacío</Form.Control.Feedback>
										)} */}

                    {!validationForm.contraseniaConfirmada ? (
                      <Form.Control.Feedback type="invalid">
                        Las contraseñas no coinciden
                      </Form.Control.Feedback>
                    ) : (
                      <Form.Control.Feedback type="valid">
                        Las contraseñas coinciden
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>

                  <Form.Text id="passwordHelpBlock2" muted>
                    La contraseña debe ser igual a la anterior
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <div className="d-grid gap-2">
              <Button disabled={showLoader} type="submit" block>
                Agregar colaborador
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default NuevoUsuario;
