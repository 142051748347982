import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Accordion, Col, Container, Image, Row } from 'react-bootstrap'

const DashboardDocumentacionFrontend = () => {
  return (
    <Container fluid>
        <Row className="mb-4">
            <Col>
                <h2>Estructura del proyecto</h2>
                <small>Diagramas con la estructura básica de la plataforma web y móvil.</small>
                <Row className="mt-3">
                    <Col>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Estructura base de la plataforma</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/estructura_plataforma.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Estructura base del frontend (plataforma web)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/estructura_frontend.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Estructura base del frontend (plataforma móvil)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/estructura_frontend_app_movil.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row className="mb-4">
            <Col>
                <h2>Flujo de información</h2>
                <small>Proceso por el que pasa la información del usuario desde que se dá de alta en SAI hasta que se registra en plataforma.</small>
                <Row className="mt-3">
                    <Col>
                    <Accordion>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Flujo de información Click+ (web)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={"https://globalclickmexico.com/img/documentacion/flujo_informacion_web.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Flujo de información Click+ (móvil)</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"65%"} src={"https://globalclickmexico.com/img/documentacion/flujo_informacion_movil.png"} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row className='mb-4'>
            <Col>
                <h2>Base de datos Click+</h2>
                <small>Diagrama UML al <b>29/01/25</b> de la base de datos de la plataforma.</small>
                <Row className="mt-3">
                    <Col>
                    <Accordion>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Diagrama UML</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <small>Puedes encontrar el archivo dentro del proyecto para visualizarlo mejor</small>
                                    <Image fluid width={"100%"} src={require("@assets/images/modelo_relacional_plataforma.png")} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row className='mb-4'>
            <Col>
                <h2>Otras aplicaciones de la familia Global Click</h2>
                <small>Diagramas e información de otros aplicativos de Global Click</small>
                <Row className="mt-3">
                    <h4>Aplicativos</h4>
                    <Col>
                    <Accordion>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Sim</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <p>Sistema para la gestión de las llamadas del departmento de relaciones públicas.</p>
                                    <p><a href='https://sim.globalclickmexico.com' target='_blank' rel='noreferrer'>Accede al aplicativo</a></p>
                                    <Image fluid width={"80%"} src={require("@assets/images/loginSim.png")} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <h4>Documentación</h4>
                    <Col>
                    <Accordion>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Diagrama UML de la base de datos del aplicativo SIM</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <Image fluid width={"80%"} src={require("@assets/images/modelo_relacional_sim.png")} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Procesos para actualizar la app móvil Click+</Accordion.Header>
                                <Accordion.Body className="text-center">
                                    <div className="ratio ratio-16x9 visor-curso">
                                        <iframe
                                        // onLoad={onLoad}
                                        allow="autoplay; encrypted-media"
                                        loading="lazy"
                                        title="Lección actual"
                                        className="embed-responsive-item"
                                        src={'https://globalclicklatam.com/documentacion-app-movil/Procesos_actualizacion_app_movil.pdf'}
                                        ></iframe>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  )
}

export default DashboardDocumentacionFrontend