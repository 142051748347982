import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

const IncidentesRP = () => {
  return (
    <Row>
        <Col>
            <TitleDashboard title={"Incidentes en Relaciones Públicas"}/>

            <p className="my-3">
                Han habido situaciones en las que solicitan apoyo para acceder a la aplicación móvil con la que hacen llamadas en el departamento de RP, al iniciar sesión le indica que hubo un problema y no le permite al usuario acceder a la app, a continuación se presentan los pasos para solucionar el error:
                <br/>

                <ul>
                    <li>Se ingresa a la base de datos llamada <b>'nzsgkwnk_sim'</b> ubicada en el VPS#1.</li>
                    <li>Existe una segunda base de datos llamada <b>'nzsgkwnk_simv2'</b>, se tiene que corroborar que en ambas bases de datos, en la tabla de usuario tengan el mismo estado cada uno de los campos para que de esta manera el sistema pueda comparar las credenciales del usuario y permitirle entrar a la aplicación ya que en casos como este se tienen distintos valores en los campos del usuario en las bases de datos, entonces no le permite acceder por dicha diferencia.</li>
                    <li>Después de esto el usuario debería poder acceder a la app</li>
                </ul>
            </p>
            
            <TitleDashboard title={"Incidentes con la app sim (móvil)"}/>

            <p className='py-3'>Para casos en los que aparezca el nombre de otro asesor dentro de la app móvil del sim, deben seguirse los siguientes pasos:
              <br/>
              <ul>
                <li>Cerrar la sesión del asesor en la app</li>
                <li>Dirigirse a la configuración del telefono y luego a las opciones de la app</li>
                <li>Una vez dentro de las opciones de la app, borrar la memoria caché de la app, esto debe eliminar los datos almaacenados dentro del teléfono.
                  <br/>
                  <Image className='mt-4' src={require("@assets/images/configAndroid.png")} width={350} height={"100%"}/>
                </li>
                <li>Iniciar sesión de nuevo y realizar una prueba para asegurarse de que ya no sale otro nombre luego de tipificar la llamada.</li>

              </ul>
            </p>
            
        </Col>
    </Row>
  )
}

export default IncidentesRP