import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Compressor from "compressorjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageDropZone } from "@components/general/ImageDropZone/ImageDropZone";
import { Notificacion } from "@components/general/Alertas/Alertas";
import { SpinnerImage } from "@components/general/Spinners/SpinnerImage/SpinnerImage";
import { getCategoriasCursos } from "@helpers/httpRequests/httpRequestsCategoriasCursos/httpRequestsCategoriasCursos";
import { updateCurso } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { useState } from "react";

export const EditarCurso = ({ curso, setCursoEditado }) => {
  let [stateCurso, setStateCurso] = useState(curso);

  const [categorias, setCategorias] = useState([]);

  const [portadaCursoActual, setPortadaCursoActual] = useState(
    curso.portadaCurso
  );

  const [spinnerImagen, setSpinnerImagen] = useState(false);

  const [showImagePreview, setImagePreview] = useState(false);

  const [nombreModificado, setNombreModificado] = useState(false);

  const [tieneEvaluacion, setTieneEvaluacion] = useState(stateCurso.tieneEvaluacion);

  const [validacion, setValidacion] = useState({
    nombre: true,
    codigoCurso: true,
    portadaCurso: true,
    estatus: true,
  });

  const handleOnChange = ({ target }) => {
    try {
      setStateCurso({
        ...stateCurso,
        [target.name]: target.value,
      });
      if(target.name === "nombre"){
        setNombreModificado(true);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleTieneEvaluacion = ({target}) => {
    setTieneEvaluacion(!tieneEvaluacion);
  }

   const getBase64Image = ({ target }) => {
    try {
      const { files } = target;

      const [file] = files;

      const fileReader = new FileReader();

      if (!file) {
        return Notificacion(
          "warning",
          "¡Ups! Algo salió mal, vuelve a cargar tu foto"
        );
      }

      //!codigo para comparacion de peso de imagen antes de ser comprimida
      fileReader.readAsDataURL(file);

      new Compressor(file, {
        quality: 0.2,
        success(result) {
          fileReader.readAsDataURL(result);

          fileReader.onload = async function () {
            setStateCurso({ ...stateCurso, portadaCurso: fileReader.result });

            setImagePreview(true);
          };
        },
        error(error) {
          console.log(error);

          Notificacion(
            "warning",
            "Ups!, algo salió mal, vuelve a cargar tu foto"
          );
        },
      });
    } catch (error) {
      console.log(error);

      Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
    }
  };

  const handleRecoverPortadaCurso = (e) => {
    setStateCurso({ ...stateCurso, portadaCurso: "" });

    setImagePreview(false);
  };


  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      if(nombreModificado){
        stateCurso.nombreModificado = true;
      }else{
        stateCurso.nombreModificado = false;
      }

      if(tieneEvaluacion){
        stateCurso.tieneEvaluacion = 1;
        stateCurso.tieneCertificacion = 1;
        stateCurso.tieneEvaluacionLeccion= 1;
      }else{
        stateCurso.tieneEvaluacion = 0;
        stateCurso.tieneCertificacion = 0;
        stateCurso.tieneEvaluacionLeccion= 0;
      }


      const results = await updateCurso(stateCurso);
      setCursoEditado(true);

      Notificacion("success", results.msg);
    } catch (error) {
      console.log("Error al actualizar los datos del curso: ", error);

      Notificacion(
        "error",
        "Algo ocurrió al actualizar los datos del curso, vuelve a intentar."
      );
    }
  };

  useEffect(() => {
    const consultarCategorias = async () => {
      try {
        const { data: categorias } = await getCategoriasCursos();

        console.log(categorias);

        setCategorias(categorias);
      } catch (error) {
        console.log("Error al consultar las categorías de los cursos: ", error);
      }
    };

    consultarCategorias();
  }, []);

  return (
    <Container>
      <Form onSubmit={handleOnSubmit}>
        <Row className="text-start">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Codigo del curso</Form.Label>

            <Form.Control
              value={stateCurso.codigoCurso}
              name="codigoCurso"
              type="text"
              disabled
            />

            <Form.Control.Feedback type="invalid">
              El código debe contener al menos 4 caracteres
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="text-start">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Nombre del curso</Form.Label>

            <Form.Control
              onChange={handleOnChange}
              value={stateCurso.nombre}
              name="nombre"
              type="text"
              className={validacion.nombre ? "is-valid" : "is-invalid"}
            />

            <Form.Control.Feedback type="invalid">
              Ingresa un nombre para el curso
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="text-start">
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Categoría</Form.Label>

            <Form.Control
              as="select"
              onChange={handleOnChange}
              name="idCategoria"
              type="text"
              value={stateCurso.idCategoria}
            >
              {categorias.map((categoria) => (
                <option
                  key={categoria.idCategoria}
                  value={categoria.idCategoria}
                >
                  {categoria.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row className="py-4 text-start">
          <Form.Group as={Col}>
            <Form.Check
              checked={tieneEvaluacion}
              id="tieneEvaluacion"
              name="tieneEvaluacion"
              label="El curso tiene evaluación final"
              type="switch"
              onChange={handleTieneEvaluacion}
              value={stateCurso.tieneEvaluacion }
            />
          </Form.Group>
        </Row>

        <Row>
          {portadaCursoActual !== "" && (
            <Form.Group as={Col} className="text-start">
              <Form.Label>Portada del curso</Form.Label>

              <Image
                rounded
                className="d-block"
                fluid
                src={
                  portadaCursoActual.includes("base64")
                    ? portadaCursoActual
                    : require(`@assets/images/portadas/${portadaCursoActual}`)
                }
              />
            </Form.Group>
          )}
        </Row>

        <Row className="mt-3">
          <Form.Group as={Col} className="text-start">
            <Form.Label>Cambiar portada</Form.Label>

            {spinnerImagen ? (
              <SpinnerImage />
            ) : (
              <ImageDropZone getImageInBase64={getBase64Image} />
            )}
          </Form.Group>
        </Row>

        <Row>
          {showImagePreview && (
            <Form.Group as={Col} className="text-start">
              <Form.Label>Nueva portada</Form.Label>

              <div className="d-block">
                <Image
                  width={250}
                  className="d-inline-block"
                  rounded
                  fluid
                  src={
                    stateCurso.portadaCurso.includes("base64")
                      ? stateCurso.portadaCurso
                      : require(`@assets/images/portadas/${stateCurso.portadaCurso}`)
                  }
                />

                <span className="pointer d-inline">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={handleRecoverPortadaCurso}
                  />
                </span>
              </div>
            </Form.Group>
          )}
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-grid gap-2 mt-4">
              <Button size="lg" variant="primary" type="submit">
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
