import { AlertSimple, Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import { Button, Col, Container, Form, Image, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { getDatosUsuario, getTokenRegistro, updateFechaExpiracion, updatePerfil } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";

import Compressor from "compressorjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpinnerImage } from "@components/general/Spinners/SpinnerImage/SpinnerImage";
import UserContext from "@context/User/UserContext";
import _ from "lodash";
import capitalizeString from "@helpers/strings/capitalizeString/capitalizeString";
import { faBoxOpen, faEdit, faSave, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { MdCancel } from "react-icons/md";
import { updatePerfilExterno } from "@helpers/httpRequests/httpRequestCursoIngles/httpRequestCursoIngles";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { actualizarSegundoUsuarioSai } from "@helpers/httpRequests/httpRequestsSAI/httpRequestsSAI";
import getUnixTimestamp from "@helpers/dates/getUnixTimestamp/getUnixTimestamp";
import { NotificationManager } from "react-notifications";
import { DropZone } from "@helpers/DropZone/DropZone";
import { formatPhoneNumber } from "@helpers/strings/formatPhoneNumber/formatPhoneNumber";
import { Chip, IconButton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { EditNote } from "@mui/icons-material";

const TabMiPerfil = (props) => {
	const history = useHistory();

	const { editable, setEditable } = props;

	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado } = contextValue;

	const { fechaUltimoAcceso: ultimoAcceso, idPerfil, folioContrato, usuarioTitular, servicioActivo, intereses, idCodigoRegion } = usuarioLogeado;

	const [cursosUsuario, setCursosUsuario] = useState(JSON.parse(usuarioLogeado.cursos));

	// state para guardar el valor inical del avatarPerfil
	const [recoveryAvatarPerfil, setRecoveryAvatarPerfil] = useState(usuarioLogeado.avatarPerfil);

	const [formValues, setFormValues] = useState({
		nombreUsuario: usuarioLogeado.nombreUsuario,
		alias: usuarioLogeado.alias,
		avatarPerfil: usuarioLogeado.avatarPerfil,
		correo: usuarioLogeado.correo,
		idLicencia: usuarioLogeado.idLicencia,
		idPersona: usuarioLogeado.idPersona,
		idPerfil: usuarioLogeado.idPerfil,
		idCredencial: usuarioLogeado.idCredencial,
		rol: usuarioLogeado.rol,
		fechaPrimerAcceso: usuarioLogeado.fechaRegistro,
		fechaExpiracion: usuarioLogeado.fechaExpiracion,
		fechaUltimoAcceso: ultimoAcceso,
		nombreCompleto: usuarioLogeado.nombreCompleto,
		apellidos: usuarioLogeado.apellidos,
		curp: usuarioLogeado.curp,
		profesion: usuarioLogeado.profesion,
		usuarioTitular: usuarioLogeado.usuarioTitular,
		numeroTelefono: usuarioLogeado.numeroTelefono,
	});

	const { nombreUsuario, alias, nombreCompleto, apellidos, correo, curp, rol, profesion, fechaPrimerAcceso, fechaUltimoAcceso, fechaExpiracion, avatarPerfil, numeroTelefono } = formValues;

	const [tiempoVigencia, setTiempoVigencia] = useState({
		diasVigencia: "",
		mesesVigencia: "",
	});

	const { diasVigencia, mesesVigencia } = tiempoVigencia;

	const [showPreviewAvatarPerfil, setShowPreviewAvatarPerfil] = useState(false);

	const [spinnerImagen, setSpinnerImagen] = useState(false);

	const [loading, setLoading] = useState(false);

	const [nombreLicencia, setNombreLicencia] = useState(usuarioLogeado.nombreLicencia);

	const [validacionInputs, setValidacionInputs] = useState({
		nombreCompleto: false,
		apellidos: false,
		curp: false,
		correo: false,
		numeroTelefono: false,
	});

	const cleanAvatarPerfilPreview = () => {
		setFormValues({
			...formValues,
			avatarPerfil: recoveryAvatarPerfil,
		});

		setShowPreviewAvatarPerfil(false);
	};

	const updatePerfilUsuario = async () => {
		try {
			setLoading(true);

			try {
				//Actualizacion de correo en ingles
				if (formValues.correo !== usuarioLogeado.correo || formValues.nombreCompleto !== usuarioLogeado.nombreCompleto || formValues.apellidos !== usuarioLogeado.apellidos) {
					await updatePerfilExterno({
						emailNuevo: formValues.correo,
						email: usuarioLogeado.correo,
						nombre: formValues.nombreCompleto,
						apellidos: formValues.apellidos,
					});
				}
			} catch (error) {
				console.log("Error al actualizar la base externa: ", error);
			}

			if (formValues.avatarPerfil === null) {
				await updatePerfil(formValues);
			} else {
				await updatePerfil({
					...formValues,
					avatarPerfil: avatarPerfil === "" ? recoveryAvatarPerfil : avatarPerfil,
				});
			}

			if (rol === typesTiposUsuario.estudiante) {
				try {
					if (!usuarioTitular || usuarioTitular === 0) {
						const { tokenRegistro } = await getTokenRegistro(folioContrato);

						const usuario = await actualizarSegundoUsuarioSai({
							folioContrato,
							tokenRegistro,
							nombre: nombreCompleto,
							apellidos,
							nombreUsuario,
							contrasenia: "",
							curp,
							correo,
							idRegion: idCodigoRegion
						});
					}
				} catch (error) {
					console.log("error al actualizar los datos del segundo usuario en sai: ", error);
				}
			}

			const { data } = await getDatosUsuario({
				tipoUsuario: formValues.rol,
				idCredencial: formValues.idCredencial,
				usuarioTitular: formValues.usuarioTitular,
			});

			const [datosUsuarioActualizados] = data;

			setContextValue({
				...contextValue,
				usuarioLogeado: datosUsuarioActualizados,
			});

			setLoading(false);

			setEditable(false);

			// NotificacionExtendida("success", "Exito al actualizar la información");
			AlertSimple("success", "La información ha sido actualizada");
		} catch (error) {
			console.log("Error al guardar los datos del usuario.", error);

			setFormValues({
				...formValues,
				avatarPerfil: usuarioLogeado.avatarPerfil,
				correo: usuarioLogeado.correo,
				nombreCompleto: usuarioLogeado.nombreCompleto,
				apellidos: usuarioLogeado.apellidos,
				curp: usuarioLogeado.curp,
				profesion: usuarioLogeado.profesion,
			});

			NotificacionExtendida("error", error.message);

			setLoading(false);
		}
	};

	const handleEditPerfil = () => {
		try {
			if (servicioActivo) {
				setEditable(true);
			} else {
				NotificationManager.info("No puedes realizar esta acción");
			}
		} catch (e) {
			console.log(e);
		}
	};

	const cancelEditPerfil = () => {
		try {
			setEditable(false);

			// setFormValues({
			// 	...formValues,
			// 	avatarPerfil: ""
			// });

			setShowPreviewAvatarPerfil(false);
		} catch (e) {
			console.log("CloseEditable");
		}
	};

	const getBase64Image = ({ target }) => {
		try {
			const { files } = target;

			const [file] = files;

			const fileReader = new FileReader();

			if (!file) {
				return Notificacion("warning", "Ups! Algo salió mal, vuelve a cargar tu foto");
			}

			//!codigo para comparacion de peso de imagen antes de ser comprimida
			fileReader.readAsDataURL(file);

			new Compressor(file, {
				quality: 0.2,
				success(result) {
					fileReader.readAsDataURL(result);

					fileReader.onload = async function () {
						setFormValues({ ...formValues, avatarPerfil: fileReader.result });

						setShowPreviewAvatarPerfil(true);
					};
				},
				error(e) {
					console.log(e);

					Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
				},
			});
		} catch (e) {
			Notificacion("warning", "Ups!, algo salió mal, vuelve a cargar tu foto");
		}
	};

	const handleOnChange = ({ target }) => {
		const { name, value } = target;

		setFormValues({
			...formValues,
			[name]: name === "numeroTelefono" ? value.replace(/[\s()-]/g, "") : value,
		});
	};

	useEffect(() => {
		if (usuarioLogeado.fechaExpiracion === null) {
			const actualizarFechaExpiracion = async () => {
				try {
					const fecha = new Date(usuarioLogeado.fechaRegistro * 1000);
					const nuevaFecha = new Date(fecha.getFullYear(), fecha.getMonth() + 18, fecha.getDate());
					const nuevaFechaUnix = Math.round(nuevaFecha.getTime() / 1000);

					const { data } = await updateFechaExpiracion({
						fechaExpiracion: nuevaFechaUnix,
						idPerfil,
					});

					setContextValue({
						...contextValue,
						usuarioLogeado: {
							...usuarioLogeado,
							fechaExpiracion: nuevaFechaUnix,
						},
					});

					setFormValues({
						...formValues,
						fechaExpiracion: nuevaFechaUnix,
					});
				} catch (error) {
					console.log(error);
				}
			};

			actualizarFechaExpiracion();
		}

		const calcularTiempoVigencia = () => {
			// const registrationDateMoment = moment.unix(formValues.fechaPrimerAcceso).utcOffset("-06:00");

			// const expirationDateMoment = registrationDateMoment.add(usuarioLogeado.tiempoVigencia, "months");

			// const currentMoment = moment();

			// console.log(usuarioLogeado.tiempoVigencia);

			// const monthsLeft = expirationDateMoment.diff(currentMoment, "months");

			// const daysLeft = expirationDateMoment.diff(currentMoment, "days");

			// setTiempoVigencia({
			// 	diasVigencia: daysLeft,
			// 	mesesVigencia: monthsLeft,
			// });
			const diferenciaEnMilisegundos = usuarioLogeado.fechaExpiracion - getUnixTimestamp();
			const diferenciaEnDias = diferenciaEnMilisegundos / 86400;

			if (diferenciaEnDias < 30) {
				console.log(`Faltan ${Math.round(diferenciaEnDias)} días`);
			} else {
				console.log(`Faltan ${Math.floor(diferenciaEnDias / 30)} meses`);
			}

			setTiempoVigencia({
				diasVigencia: Math.round(diferenciaEnDias),
				mesesVigencia: Math.floor(diferenciaEnDias / 30),
			});
		};

		calcularTiempoVigencia();

		console.log(usuarioLogeado.intereses);
	}, []);

	return (
		<Container fluid>
			<Row className="mb-4">
				<Col sm={12} md align="right">
					{!editable && (
						// <Button variant="warning" onClick={handleEditPerfil} className="botonPerfil d-flex align-content-center">
						// 	<i className="large material-icons ">border_color</i> Editar
						// </Button>
						<Button variant="warning" onClick={handleEditPerfil} className=" d-flex align-items-center">
							<FontAwesomeIcon icon={faEdit} className="mx-1" /> Editar
						</Button>
					)}

					{editable && (
						<>
							{/* <Button variant="success" onClick={updatePerfilUsuario} className="me-1">
								<i className="large material-icons">save</i> Guardar
							</Button> */}
							<Button variant="success" onClick={updatePerfilUsuario} className="me-1">
								{loading ? (
									<>
										<FontAwesomeIcon icon={faSpinner} spin className="mx-1" /> Guardando...
									</>
								) : (
									<>
										<FontAwesomeIcon icon={faSave} className="mx-1" /> Guardar
									</>
								)}
							</Button>

							{/* <Button variant="danger" onClick={cancelEditPerfil} className="ms-1">
								<i className="large material-icons">cancel</i> Cancelar
							</Button> */}

							<Button variant="danger" onClick={cancelEditPerfil} className="ms-1">
								<MdCancel /> Cancelar
							</Button>
						</>
					)}
				</Col>
			</Row>
			{/* Fin de fila de boton editar */}

			{/*----------------Row para la información personal---------------------- */}

			<Row className="justify-content-center">
				<Col>
					<Container fluid>
						<h2>Tu cuenta</h2>

						<Row className="mt-5">
							<Col xs={12} md={4} className="text-center mb-5">
								{editable && (
									<Form.Group>
										{showPreviewAvatarPerfil && formValues.avatarPerfil !== null ? (
											<>
												<h6 className="mt-3 subtitulos">Vista previa</h6>

												<img width="250" height="250" className="mt-2 rounded-circle vista-previa" src={avatarPerfil} alt="Imagen de perfil"></img>

												<Col className="mt-2 ">
													<h5>
														<FontAwesomeIcon onClick={cleanAvatarPerfilPreview} icon={faTrash} />
													</h5>
												</Col>
											</>
										) : (
											<>{spinnerImagen ? <SpinnerImage /> : <DropZone getImageInBase64={getBase64Image} />}</>
										)}
									</Form.Group>
								)}

								{!editable && (
									<Image
										width="300"
										height="300"
										roundedCircle
										className="shadow borderCircularImg"
										src={avatarPerfil !== null ? avatarPerfil : require("@assets/images/avatar.png")}
										alt={"Foto de perfil"}
									/>
								)}
							</Col>

							<Col xs={12} md={4} className="mb-lg-5">
								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Folio de Contrato</Form.Label>

									<Form.Control type="text" value={folioContrato} disabled />
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Nombre</Form.Label>

									<Form.Control
										className={validacionInputs.nombreCompleto ? "is-invalid" : ""}
										type="text"
										autoComplete="off"
										onChange={handleOnChange}
										name="nombreCompleto"
										value={nombreCompleto}
										disabled={!editable}
									/>

									<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Apellidos</Form.Label>

									<Form.Control
										className={validacionInputs.apellidos ? "is-invalid" : ""}
										type="text"
										autoComplete="off"
										onChange={handleOnChange}
										name="apellidos"
										value={apellidos}
										disabled={!editable}
									/>

									<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">CURP</Form.Label>

									<Form.Control className={validacionInputs.curp ? "is-invalid" : ""} type="text" autoComplete="off" onChange={handleOnChange} name="curp" value={curp} disabled={!editable} />

									<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Número de teléfono</Form.Label>

									{idCodigoRegion !== 1 ?(
										<Form.Control
											className={validacionInputs.numeroTelefono ? "is-invalid" : ""}
											type="text"
											autoComplete="off"
											onChange={handleOnChange}
											name="numeroTelefono"
											value={numeroTelefono === "" ? "" : numeroTelefono}
											disabled={!editable}
											maxLength={11}
										/>
									): (
										<Form.Control
											className={validacionInputs.numeroTelefono ? "is-invalid" : ""}
											type="text"
											autoComplete="off"
											onChange={handleOnChange}
											name="numeroTelefono"
											value={numeroTelefono === "" ? "" : formatPhoneNumber(numeroTelefono)}
											disabled={!editable}
											maxLength={15}
										/>
									)}

									<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
								</Form.Group>
							</Col>

							<Col xs={12} md={4}>
								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Correo</Form.Label>

									<Form.Control className={validacionInputs.correo ? "is-invalid" : ""} type="text" autoComplete="off" onChange={handleOnChange} name="correo" value={correo} disabled={!editable} />

									<Form.Control.Feedback type="invalid">Este campo no puede ir vacío</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Nombre de usuario</Form.Label>
									<OverlayTrigger placement={"top"} overlay={<Tooltip id={`tooltip`}>Consulta el apartado "Cambio de nombre de usuario" para modificar esta información".</Tooltip>}>
										<Form.Control disabled type="text" autoComplete="off" name="nombreUsuario" value={nombreUsuario} />
									</OverlayTrigger>
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Alias de usuario</Form.Label>

									<Form.Control type="text" name="alias" value={alias} disabled />
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Label className="subtitulos">Profesion</Form.Label>

									<Form.Control type="text" autoComplete="off" onChange={handleOnChange} name="profesion" value={profesion} disabled={!editable} />
								</Form.Group>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>

			<Row className="justify-content-center mb-5">
				<Col>
					<Container fluid>
						<h2>
							Tus intereses{" "}
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip>
										Editar intereses
									</Tooltip>
								}>
								<IconButton size="large" onClick={() => history.replace(dashboardRoutesPaths.intereses)}>
									<EditNote />
								</IconButton>
							</OverlayTrigger>
						</h2>

						<Row className="mt-2">
							<Col className="mt-2" xs={12} md={{ span: 4, offset: intereses !== null ? 0 : 4 }}>
								{intereses !== null ? (
									intereses.split(",").map((tema, idx) => <Chip label={tema} key={idx} className="mx-3 mb-3" />)
								) : (
									<Stack spacing={2}>
										<Typography gutterBottom variant="overline">
											No has seleccionado tus temas de interés
										</Typography>

										<div className="text-center my-3">
											<FontAwesomeIcon icon={faBoxOpen} size="5x" />
										</div>

										<Button variant="success" onClick={() => history.replace(dashboardRoutesPaths.intereses)}>
											Seleccionar intereses
										</Button>
									</Stack>
								)}
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>

			<Row className="mt-3 mb-5">
				<Container fluid>
					<h2>Tu memebresía</h2>

					<Row className="mt-5">
						<Col sm md={5} className="p-3 p-md-5">
							{/* ------------------------------- Primer Col ---------------------------------- */}

							<ul className="list-unstyled lista-datos-membresia">
								<li className="mt-3 ">
									<span>Licencia: </span>
									{nombreLicencia} - {usuarioLogeado.tiempoVigencia} meses
								</li>
								<li className="mt-3">
									<span>Rol: </span> {rol === "lectura_veloz" ? "Lectura Inteligente" : capitalizeString(rol)}
								</li>
								<li className="mt-3">
									<span>Fecha de registro: </span> {getDateFromUnix(fechaPrimerAcceso)}
								</li>
								<li className="mt-3">
									<span>Fecha ultimo acceso: </span> {fechaUltimoAcceso !== null ? getDateFromUnix(fechaUltimoAcceso) : "No habías accesado antes"}
								</li>

								<li className="mt-3">
									<span>Fecha de expiración: </span> {getDateFromUnix(fechaExpiracion)}
								</li>

								<li className="mt-3">
									<span>Tiempo restante: </span>
									{mesesVigencia > 0 ? `${mesesVigencia} ${mesesVigencia > 1 ? "meses" : "mes"}` : `${diasVigencia} días`}
								</li>
							</ul>
						</Col>

						<Col sm md={7} className="contenedor-cursos">
							<h5 className="subtitulos px-4 pt-4 pb-0">Mis cursos</h5>

							<Container>
								<Row>
									{(() => {
										const chunkSize = cursosUsuario.length > 1 ? Math.ceil(cursosUsuario.length / 2) : cursosUsuario.length;

										const chunks = _.chunk(cursosUsuario, chunkSize);

										return chunks.map((chunk, idxChunk) => (
											<Col key={idxChunk}>
												<ul>
													{chunk.map((curso, idxCurso) => (
														<li key={idxCurso}>
															<h6>{curso.nombre}</h6>
														</li>
													))}
												</ul>
											</Col>
										));
									})()}
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>
			</Row>
		</Container>
	);
};

export default TabMiPerfil;
