import { useSprings, animated } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';

const SplitText = ({
  text = '',
  delay = 100,
  animationFrom = { opacity: 0, transform: 'translate3d(0,40px,0)' },
  animationTo = { opacity: 1, transform: 'translate3d(0,0,0)' },
  easing = 'easeOutCubic',
  threshold = 0.1,
  rootMargin = '-100px',
  textAlign = 'center',
  onLetterAnimationComplete,
  width, 
  modoOscuro,
}) => {
  const letters = text.split('');
  const [inView, setInView] = useState(false);
  const ref = useRef();
  const animatedCount = useRef(0);
  const [responsiveClassName, setResponsiveClassName] = useState("tituloPrincipalLg");

  const setClassName = (widthValue) => {
    console.log(widthValue)
    if(widthValue > 768 && widthValue < 1024){
      setResponsiveClassName("tituloPrincipalResponsive");
      console.log("Entra en la validacion")
    }else if (widthValue > 1024 && widthValue < 2000){
      setResponsiveClassName("tituloPrincipal");
    }else if (widthValue < 768) {
      setResponsiveClassName("tituloPrincipalSm")
    }else{
      console.log("Entra en el else de la validacion")
      setResponsiveClassName("tituloPrincipal")
    }
  }

  useEffect(() => {
    setClassName(width);
  }, [])
  
  useEffect(() => {
    setClassName(width);
  }, [width])
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold, rootMargin }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [threshold, rootMargin]);

  const springs = useSprings(
    letters.length,
    letters.map((_, i) => ({
      from: animationFrom,
      to: inView
        ? async (next) => {
          await next(animationTo);
          animatedCount.current += 1;
          if (animatedCount.current === letters.length && onLetterAnimationComplete) {
            onLetterAnimationComplete();
          }
        }
        : animationFrom,
      delay: i * delay,
      config: { easing },
    }))
  );

  return (
    <h2
      ref={ref}
      className={`split-parent ${responsiveClassName}`}
      style={{ textAlign, display: 'inline', overflow: 'hidden' }}
    >
      {springs.map((props, index) => (
        <animated.span
          className="welcome-text"
          key={index}
          style={{
            ...props,
            display: 'inline-block',
            willChange: 'transform, opacity',
            color: modoOscuro ? '#fff':'#000'
          }}
        >
          {letters[index] === ' ' ? ' ' : letters[index]}
        </animated.span>
      ))}
    </h2>
  );
};

export default SplitText;