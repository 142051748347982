import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getCursos } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import moment from "moment";
import AccordionEstadisticas from "./components/AccordionEstadisticas";
import GraficasEstadisticas from "./components/GraficasEstadisticas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTableRecipient from "@helpers/dataTableRecipient/dataTableRecipient";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import columnsUsuariosSuspendidos from "./columnsUsuariosSuspendidos/columnsUsuariosSuspendidos";
import columnsDetallesMos from "./columnsDetallesMos/columnsDetallesMos";

import "@assets/css/components/DashboardEstadisticas/style.css";
import { getCertificadosPorMes, getDetallesExamenesMos, getDetallesUsuariosSuspendidos, getEstadisticas, getEstadisticasMos, getHorasJuego, getPromediosPorMes, getVisitasSala } from "@helpers/httpRequests/httpRequestEstadisticas/httpRequestsEstadisticas";
import { getAgendasAsesorias } from "@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias";
import columnsAgendasAsesorias from "./columnsDetallesAgendas/columnsDetallesAgendas";
import { getUsuariosActivosAppMovil } from "@helpers/httpRequests/httpRequestsUsuariosApp/httpRequestsApp";
import columnsUsuariosAppMovil from "./columnsUsuariosAppMovil/columnsAppMovil";

const DashboardEstadisticas = () => {
	const [usuariosPlataforma, setUsuariosPlataforma] = useState(0);

	const [totalEstudiantes, setTotalEstudiantes] = useState(0);

	const [totalEmpleados, setTotalEmpleados] = useState(0);

	const [totalSuspendidos, setTotalSuspendidos] = useState(0);

	const [totalVencidos, setTotalVencidos] = useState(0);

	const [cursoMasVisto, setCursoMasVisto] = useState("");

	const [categorias, setCategorias] = useState(0);

	const [cursos, setCursos] = useState(0);

	const [nombresCursos, setNombresCursos] = useState([]);

	const [secciones, setSecciones] = useState(0);

	const [lecciones, setLecciones] = useState(0);

	const [certificados, setCertificados] = useState(0);

	const [promedioGeneral, setPromedioGeneral] = useState(0);

	const [publicacionesEmpresariales, setPublicacionesEmpresariales] = useState(0);

	const [temasForoAyuda, setTemasForoAyuda] = useState(0);

	//DATA PARA GRAFICOS
	const [certificadosCursos, setCertificadosCursos] = useState([]);

	const [certificadosData, setCertificadosData] = useState([]);

	const [certificadosMes, setCertificadosMes] = useState([]);

	//Etiqueta para grafica de meses
	const [labelCertificadosMes, setLabelCertificadosMes] = useState([]);

	// Obtén la fecha actual
	const fechaActual = moment();

	// Obtén el año actual
	const añoActual = fechaActual.year();

	const [formValuesCertificados, setFormValuesCertificados] = useState({
		periodoCertificacion: añoActual,
	});

	//states para la sala de entretenimientos
	const [tiempoJuego, setTiempoJuego] = useState({
		series: [],
		meses: [],
		year: añoActual,
	});

	const [cantidadTiempoJuego, setCantidadTiempoJuego] = useState("");

	const [visitasSala, setVisitasSala] = useState({
		series: [],
		meses: [],
		year: añoActual,
	});

	const [cantidadVisitasSala, setCantidadVisitasSala] = useState(0);

	const [juegoMasUsado, setJuegoMasUsado] = useState("");

	const [formatoTiempoJuego, setFormatoTiempoJuego] = useState("horas");

	const [examenesMosRealizados, setExamenesMosRealizados] = useState(0);

	const [examenesAprobados, setExamenesAprobados] = useState(0);

	const [examenesReprobados, setExamenesReprobados] = useState(0);

	const [mostrarUsuariosSuspendidos, setMostrarUsuariosSuspendidos] = useState(false);

	const [mostrarDetallesMos, setMostrarDetallesMos] = useState(false);

	const [dataMos, setDataMos] = useState([]);

	const [dataUsuariosSuspendidos, setDataUsuariosSuspendidos] = useState([]);

	const [usuariosSuite, setUsuariosSuite] = useState(0);

	const [vecesUsadaSuite, setVecesUsadaSuite] = useState(0);

	const [herramientaSuite, setHerramientaSuite] = useState('');

	const [agendasAsesorias, setAgendasAsesorias] = useState([]);

	const [asesoriasResueltas, setAsesoriasResultas] = useState([]);

	const [mostrarAgendasAsesorias, setMostrarAgendasAsesorias] = useState(false);

	const [usuariosAppMovil, setUsuariosAppMovil] = useState([]);

	const [mostrarUsuariosAppMovil, setMostrarUsuariosAppMovil] = useState(false);

	const graficaCertificadosEmitidosPorCurso = {
		series: [
			{
				name: "Certificados emitidos",
				data: certificadosData,
			},
		],
		options: {
			colors: ["#00152a"],
			chart: {
				type: "bar",
				height: 350,
			},
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				categories: certificadosCursos,
			},
		},
	};

	const certificadosEmitidosPorMes = {
		options: {
			chart: {
				type: "donut",
			},
			labels: labelCertificadosMes,
		},
		series: certificadosMes, // Los datos para tu gráfica de pie
	};

	const handleOnChangeCertificaciones = ({ currentTarget }) => {
		const { name, value } = currentTarget;

		setFormValuesCertificados({
			...formValuesCertificados,
			[name]: value,
		});
	};

	const handleOnChangeHorasJuego = ({ currentTarget }) => {
		const { value } = currentTarget;

		setTiempoJuego({
			...tiempoJuego,
			year: value,
		});
	};

	const handleOnChangeVisitasSala = ({ currentTarget }) => {
		const { value } = currentTarget;

		setVisitasSala({
			...visitasSala,
			year: value,
		});
	};

	const buscarCertificaciones = async () => {
		try {
			//Funcion para recuperar las certificaciones de cierto año

			const data = await getCertificadosPorMes({ year: formValuesCertificados.periodoCertificacion });
			const arregloCantidadMeses = [];
			const arregloNombreMeses = [];

			setCertificadosMes([]);
			setLabelCertificadosMes([]);

			data.map((dato, idx) => {
				if (dato.cantidad > 0) {
					arregloNombreMeses.push(dato.mes);
					arregloCantidadMeses.push(dato.cantidad);
				}
			});

			setCertificadosMes(arregloCantidadMeses);
			setLabelCertificadosMes(arregloNombreMeses);
		} catch (error) {
			console.log(error);
		}
	};

	//Promedios por curso

	const [promediosPorMes, setPromediosPorMes] = useState({
		series: [],
		cursos: [],
	});

	const configPromediosCurso = {
		series: [
			{
				name: "Promedios por mes",
				data: promediosPorMes.series.length > 0 ? promediosPorMes.series : [],
			},
		],
		options: {
			colors: ["#525252"],
			chart: {
				height: 300,
				type: "bar",
			},
			plotOptions: {
				bar: {
					borderRadius: 10,
					dataLabels: {
						position: "top", // top, center, bottom
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (val) {
					return val + "%";
				},
				offsetY: -20,
				style: {
					fontSize: "12px",
					colors: ["#304758"],
				},
			},

			xaxis: {
				categories: promediosPorMes.cursos,
				position: "top",
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				crosshairs: {
					fill: {
						type: "gradient",
						gradient: {
							colorFrom: "#D8E3F0",
							colorTo: "#BED1E6",
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5,
						},
					},
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					formatter: function (val) {
						return val + "%";
					},
				},
			},
		},
	};

	useEffect(() => {
		
		const obtenerAgendasAsesorias = async() => {
			try {
				const asesorias = await getAgendasAsesorias();
				console.table(asesorias)
				setAgendasAsesorias(asesorias);
				asesorias.map((asesoria) => {
					if(asesoria.estatus === 'Resuelta'){
						asesoriasResueltas.push(asesoria);
					}
				})
			} catch (error) {
				console.log(error);
			}
		}

		obtenerAgendasAsesorias();
		const obtenerEstadisticas = async () => {

			try {

				const mosEstadisticas = await getEstadisticasMos();

				setExamenesMosRealizados(mosEstadisticas.data[1]);

				setExamenesAprobados(mosEstadisticas.data[0]);

				setExamenesReprobados(mosEstadisticas.data[2]);
				const detallesMos = await getDetallesExamenesMos();

				setDataMos(Array.isArray(detallesMos.data.data) ? detallesMos.data.data : [detallesMos.data.data]);

			
				const data = await getEstadisticas();

				setUsuariosPlataforma(data[0].total_usuarios);
				setTotalEstudiantes(data[1].total_estudiantes);
				setTotalEmpleados(data[2].total_empleados);
				setTotalSuspendidos(data[3].total_estudiantes_suspendidos);
				setTotalVencidos(data[4].total_estudiantes_vencidos);
				setCursoMasVisto(data[5][0].nombre);
				setCategorias(data[7].cantidad_categorias);
				setCursos(data[6].cantidad_cursos);
				setSecciones(data[9].cantidad_secciones);
				setLecciones(data[8].cantidad_lecciones);
				setCertificados(data[10].cantidad_certificados);
				setPromedioGeneral(data[11].promedio_general);
				setPublicacionesEmpresariales(data[12].cantidad_publicaciones_empresariales);
				setTemasForoAyuda(data[13].temas_foro_ayuda);
				setUsuariosSuite(data[18].cantidad_usuarios_suite);
				setVecesUsadaSuite(data[19].veces_usada);
				setHerramientaSuite(data[20].codigoHerramienta);

				const minutos = data[15].tiempo_juego / 60;

				const duracion = moment.duration(minutos, "minutes");

				setCantidadTiempoJuego(`${duracion._data.days} días, ${duracion._data.hours}hrs, ${duracion._data.minutes}min`);
				setCantidadVisitasSala(data[16].total_visitas);

				switch (data[17].nombreJuego) {
					case "memo":
						setJuegoMasUsado("Memorama");
						break;
					case "snake":
						setJuegoMasUsado("Snake");
						break;
					case "dino":
						setJuegoMasUsado("Dino game");
						break;
					default:
						setJuegoMasUsado("Aún no hay un juego más usado");

						break;
				}

				if(!Array.isArray(data[14])){
					let arregloDatosCertificados = [data[14]];
					certificadosCursos.push(data[14] === null ? [] : arregloDatosCertificados[0].nombre);
					certificadosData.push(data[14] === null ? [] : arregloDatosCertificados[0].cantidad_certificados_emitidos);
				}else{

					data[14].map((datoCertificados) => {
						certificadosCursos.push(data[14] === null ? [] : datoCertificados.nombre);
						certificadosData.push(data[14] === null ? [] : datoCertificados.cantidad_certificados_emitidos);
					});
				}


				const usuariosSuspendidos = await getDetallesUsuariosSuspendidos();
				setDataUsuariosSuspendidos(Array.isArray(usuariosSuspendidos.data.data) ? usuariosSuspendidos.data.data : [usuariosSuspendidos.data.data]);

			} catch (error) {
				console.log(error);
				console.log(error.message);

				setUsuariosPlataforma(0);
				setTotalEstudiantes(0);
				setTotalEmpleados(0);
				setTotalSuspendidos(0);
				setTotalVencidos(0);
				setCursoMasVisto(0);
				setCategorias(0);
				setCursos(0);
				setSecciones(0);
				setLecciones(0);
				setCertificados(0);
				setPromedioGeneral(0);
				setPublicacionesEmpresariales(0);
				setTemasForoAyuda(0);
				setCantidadTiempoJuego("");
				setCantidadVisitasSala(0);
				setJuegoMasUsado("");
			}
		};

		obtenerEstadisticas();

		const obtenerCursos = async () => {
			try {
				const { data } = await getCursos();
				if (Array.isArray(data)) {
					data.map((nombreCurso) => {
						nombresCursos.push(nombreCurso.nombre);
					});
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtenerCursos();

		const buscarCertificaciones = async () => {
			try {
				//Funcion para recuperar las certificaciones de cierto año

				const data = await getCertificadosPorMes({ year: formValuesCertificados.periodoCertificacion });
				const arregloCantidadMeses = [];
				const arregloNombreMeses = [];

				// setCertificadosMes(data);
				setCertificadosMes([]);
				setLabelCertificadosMes([]);

				data.map((dato, idx) => {
					if (dato.cantidad > 0) {
						arregloNombreMeses.push(dato.mes);
						arregloCantidadMeses.push(dato.cantidad);
					}
				});

				setCertificadosMes(arregloCantidadMeses);
				setLabelCertificadosMes(arregloNombreMeses);
			} catch (error) {
				console.log(error);
			}
		};

		buscarCertificaciones();

		const promediosMensuales = async () => {
			try {
				const data = await getPromediosPorMes();

				data.map((datoMes) => {
					promediosPorMes.series.push(datoMes.promedio);
					promediosPorMes.cursos.push(datoMes.nombre);
				});
			} catch (error) {
				console.log(error);
			}
		};

		promediosMensuales();

		const obtenerHorasDeJuego = async () => {
			try {
				const data = await getHorasJuego({
					añoBusqueda: añoActual,
				});
				const arregloAux = {
					series: [],
					meses: [],
				};

				data.map((dato) => {
					if (dato.cantidad > 0) {
						const horas = dato.cantidad / 3600;
						arregloAux.series.push(horas.toFixed(0));
						arregloAux.meses.push(dato.mes.slice(0, 3));
					}
				});

				setTiempoJuego({
					...tiempoJuego,
					series: arregloAux.series,
					meses: arregloAux.meses,
				});
			} catch (error) {
				console.log(error);
			}
		};

		obtenerHorasDeJuego();

		const obtenerVisitasSala = async () => {
			try {
				const data = await getVisitasSala({
					añoBusqueda: añoActual,
				});
				const arregloAux = {
					series: [],
					meses: [],
				};

				data.map((dato) => {
					if (dato.cantidad > 0) {
						arregloAux.series.push(dato.cantidad);
						arregloAux.meses.push(dato.mes.slice(0, 3));
					}
				});

				setVisitasSala({
					...visitasSala,
					series: arregloAux.series,
					meses: arregloAux.meses,
				});
			} catch (error) {
				console.log(error);
			}
		};

		obtenerVisitasSala();

		const obtenerUsuariosAppMovil = async() => {
			try {
				const {data} = await getUsuariosActivosAppMovil();
				// console.table(data);

				if(Array.isArray(data)){
					setUsuariosAppMovil(data);
				}else{
					setUsuariosAppMovil([data]);
				}

			} catch (error) {
				console.log("Error al recuperar los usuarios activos de la app móvil")
			}
		}

		obtenerUsuariosAppMovil();


	}, []);

	const buscarHorasJuego = async (añoBusqueda, formato = "horas") => {
		try {
			const data = await getHorasJuego({
				añoBusqueda,
			});

			const arregloAux = {
				series: [],
				meses: [],
			};

			data.map((dato) => {
				if (dato.cantidad > 0) {
					const tiempo = formato === "horas" ? dato.cantidad / 3600 : dato.cantidad / 60;
					arregloAux.series.push(tiempo.toFixed(0));
					arregloAux.meses.push(dato.mes.slice(0, 3));
				}
			});

			setTiempoJuego({
				...tiempoJuego,
				series: arregloAux.series,
				meses: arregloAux.meses,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const configHorasJuego = {
		series: [
			{
				name: `Tiempo de juego`,
				data: tiempoJuego.series,
			},
		],
		options: {
			colors: ["#822626"],
			chart: {
				height: 350,
				type: "line",
				zoom: {
					enabled: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "straight",
			},
			grid: {
				row: {
					colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
					opacity: 0.5,
				},
			},
			xaxis: {
				categories: tiempoJuego.meses,
			},
			yaxis: {
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					formatter: function (val) {
						const formato = formatoTiempoJuego === "horas" ? "hrs" : "min";
						return val === 0 ? `Menos de ${formatoTiempoJuego === "horas" ? "una hora" : "un minuto"}` : val + " " + formato;
					},
				},
			},
		},
	};

	const handleFormatoDeTiempo = ({ currentTarget }) => {
		const { name } = currentTarget;
		setFormatoTiempoJuego(name);
		buscarHorasJuego(tiempoJuego.year, name);
	};

	const buscarVisitasSala = async (añoBusqueda) => {
		try {
			const data = await getVisitasSala({
				añoBusqueda,
			});

			const arregloAux = {
				series: [],
				meses: [],
			};

			data.map((dato) => {
				if (dato.cantidad > 0) {
					arregloAux.series.push(dato.cantidad);
					arregloAux.meses.push(dato.mes.slice(0, 3));
				}
			});

			setVisitasSala({
				...visitasSala,
				series: arregloAux.series,
				meses: arregloAux.meses,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const configVisitasSala = {
		series: [
			{
				name: "Visitas en el mes",
				data: visitasSala.series,
			},
		],
		options: {
			colors: ["#ff9900"],
			chart: {
				type: "bar",
				height: 380,
				zoom: {
					enabled: false,
				},
			},
			xaxis: {
				categories: visitasSala.meses,
			},

			tooltip: {
				x: {
					formatter: function (val) {
						return val;
					},
				},
			},
		},
	};

	return (
		<Container fluid className="mt-5">
			<Row>
				<Col className="col-12 col-xl-4 contenedor-estadisticas1">
					<AccordionEstadisticas
						usuariosPlataforma={usuariosPlataforma}
						totalEstudiantes={totalEstudiantes}
						totalEmpleados={totalEmpleados}
						totalSuspendidos={totalSuspendidos}
						totalVencidos={totalVencidos}
						cursoMasVisto={cursoMasVisto}
						categorias={categorias}
						cursos={cursos}
						secciones={secciones}
						lecciones={lecciones}
						certificados={certificados}
						promedioGeneral={promedioGeneral}
						examenesMosRealizados={examenesMosRealizados}
						examenesAprobados={examenesAprobados}
						examenesReprobados={examenesReprobados}
						cantidadTiempoJuego={cantidadTiempoJuego}
						cantidadVisitasSala={cantidadVisitasSala}
						juegoMasUsado={juegoMasUsado}
						publicacionesEmpresariales={publicacionesEmpresariales}
						setMostrarUsuariosSuspendidos={setMostrarUsuariosSuspendidos}
						setMostrarDetallesMos={setMostrarDetallesMos}
						mostrarDetallesMos={mostrarDetallesMos}
						mostrarUsuariosSuspendidos={mostrarUsuariosSuspendidos}
						usuariosSuite={usuariosSuite}
						vecesUsadaSuite={vecesUsadaSuite}
						herramientaSuite={herramientaSuite}
						temasForoAyuda={temasForoAyuda}
						agendasAsesorias={agendasAsesorias}
						asesoriasResueltas={asesoriasResueltas}
						mostrarAgendasAsesorias={mostrarAgendasAsesorias}
						setMostrarAgendasAsesorias={setMostrarAgendasAsesorias}
						usuariosAppMovil={usuariosAppMovil}
						mostrarUsuariosAppMovil={mostrarUsuariosAppMovil}
						setMostrarUsuariosAppMovil={setMostrarUsuariosAppMovil}
					/>

				</Col>

				{/*=================== Inicio de graficas del componente ================================ */}

				{(!mostrarDetallesMos && !mostrarUsuariosSuspendidos && !mostrarAgendasAsesorias && !mostrarUsuariosAppMovil) &&  (
					<Col className="col-12 col-xl-8 contenedor-estadisticas2 contenedor-graficos">
						<GraficasEstadisticas
							certificadosData={certificadosData}
							graficaCertificadosEmitidosPorCurso={graficaCertificadosEmitidosPorCurso}
							handleOnChangeCertificaciones={handleOnChangeCertificaciones}
							formValuesCertificados={formValuesCertificados}
							certificadosEmitidosPorMes={certificadosEmitidosPorMes}
							buscarCertificaciones={buscarCertificaciones}
							handleOnChangeHorasJuego={handleOnChangeHorasJuego}
							tiempoJuego={tiempoJuego}
							buscarHorasJuego={buscarHorasJuego}
							formatoTiempoJuego={formatoTiempoJuego}
							handleFormatoDeTiempo={handleFormatoDeTiempo}
							configHorasJuego={configHorasJuego}
							handleOnChangeVisitasSala={handleOnChangeVisitasSala}
							visitasSala={visitasSala}
							buscarVisitasSala={buscarVisitasSala}
							configPromediosCurso={configPromediosCurso}
							configVisitasSala={configVisitasSala}
						/>
					</Col>
				)}

				{mostrarUsuariosSuspendidos && (
					<Col className="col-12 col-xl-8 contenedor-estadisticas2 contenedor-graficos">

						<Row>
							<span onClick={() => {
								setMostrarUsuariosSuspendidos(false);
							}} className="pointer">
								<FontAwesomeIcon size={24} color="gray" className="mx-2" icon={faArrowLeft} />
								Volver
							</span>
						</Row>

						<Row className="mt-3">
							<Row>
								<Col>
									<h3>Usuarios suspendidos de la plataforma</h3>
								</Col>
							</Row>

							{dataUsuariosSuspendidos.length > 0 && (
								<Row>
									<Col>
										<DataTableRecipient columns={columnsUsuariosSuspendidos} data={dataUsuariosSuspendidos} />
									</Col>
								</Row>
							)}
						</Row>

					</Col>
				)}

				{mostrarDetallesMos && (
					<Col className="col-12 col-xl-8 contenedor-estadisticas2 contenedor-graficos">

						<Row>
							<span onClick={() => {
								setMostrarDetallesMos(false);
							}} className="pointer">
								<FontAwesomeIcon size={24} color="gray" className="mx-2" icon={faArrowLeft} />
								Volver
							</span>
						</Row>

						<Row className="mt-3">
							<Row>
								<Col>
									<h3>Detalles de los examenes MOS</h3>
								</Col>
							</Row>

							{dataMos.length > 0 && (
								<Row>
									<Col>
										<DataTableRecipient columns={columnsDetallesMos} data={dataMos} />
									</Col>
								</Row>
							)}
						</Row>

					</Col>
				)}

				{mostrarAgendasAsesorias && (
					<Col className="col-12 col-xl-8 contenedor-estadisticas2 contenedor-graficos">

					<Row>
						<span onClick={() => {
							setMostrarAgendasAsesorias(false);
						}} className="pointer">
							<FontAwesomeIcon size={24} color="gray" className="mx-2" icon={faArrowLeft} />
							Volver
						</span>
					</Row>

					<Row className="mt-3">
						<Row>
							<Col>
								<h3>Detalles de agendas de asesoría</h3>
							</Col>
						</Row>

						{agendasAsesorias.length > 0 && (
							<Row>
								<Col>
									<DataTableRecipient columns={columnsAgendasAsesorias} data={agendasAsesorias} />
								</Col>
							</Row>
						)}
					</Row>

				</Col>
				)}

				{mostrarUsuariosAppMovil && (
					<Col className="col-12 col-xl-8 contenedor-estadisticas2 contenedor-graficos">

					<Row>
						<span onClick={() => {
							setMostrarUsuariosAppMovil(false);
						}} className="pointer">
							<FontAwesomeIcon size={24} color="gray" className="mx-2" icon={faArrowLeft} />
							Volver
						</span>
					</Row>

					<Row className="mt-3">
						<Row>
							<Col>
								<h3>Usuarios registrados en la app móvil</h3>
							</Col>
						</Row>

						{usuariosAppMovil.length > 0 && (
							<Row>
								<Col>
									<DataTableRecipient columns={columnsUsuariosAppMovil} data={usuariosAppMovil} />
								</Col>
							</Row>
						)}
					</Row>

				</Col>
				)}

			</Row>
		</Container>
	);
};

export default DashboardEstadisticas;
