/* eslint-disable jsx-a11y/anchor-is-valid */

import { Col, Container, Nav, NavDropdown, Navbar, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";

import UserContext from "@context/User/UserContext";

import "primeicons/primeicons.css";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import { getNotificacionesUsuario, updateNotificacionUsuario } from "@helpers/httpRequests/httpRequestsUsuarios/httpRequestsUsuarios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Badge } from "primereact/badge";
import { useRef } from "react";
import typesCodigosNotificacionUsuario from "@helpers/types/typesCodigosNotificacionUsuario";
import { ModalSweetAlert } from "@components/general/Alertas/Alertas";
import { FaRegClock } from "react-icons/fa";
import ThemeSwitcher from "@components/general/ThemeSwitcher/ThemeSwitcher";
import dashboardRoutesPaths from "@helpers/dashboardRoutesPaths/dashboardRoutesPaths";
import { enviarFormularioRecuperacionContrasenia, solicitudRestablecimientoNombreUsuario } from "@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers";
import { NotificationManager } from "react-notifications";
import { Backdrop, CircularProgress } from "@mui/material";

const DatosUsuario = (props) => {
	const { contextValue, setContextValue } = useContext(UserContext);

	const { usuarioLogeado, sesionVerComo = false, dataAdministradorSesionVerComo = {} } = contextValue;

	const { avatarPerfil, segundoPerfilRegistrado, nombreCompleto, apellidos, rol, usuarioExtra, idPerfil, usuarioTitular } = usuarioLogeado;

	const { nombreCompleto: nombreCompletoAdmin, apellidos: apellidosAdmin } = dataAdministradorSesionVerComo;

	const [usuarioAdicional, setUsuarioAdicional] = useState(usuarioExtra === 1 || rol === typesTiposUsuario.estudiante ? true : false);

	const [notificacionesUsuario, setNotificacionesUsuario] = useState([]);

	const [notificacionesNoLeidas, setNotificacionesNoLeidas] = useState([]);

	const [cantidadNotificacionesNoLeidas, setCantidadNotificacionesNoLeidas] = useState(0);

	const { referencia, handleChangeComponent, handleProfile, handleProfileSelection, handleRegisterSecondaryUser, handleLogout, width } = props;

	const op = useRef(null);

	const [openBackdrop, setOpenBackdrop] = useState(false);

	const handleCloseBackdrop = () => {
		setOpenBackdrop(false);
	};

	const handleOpenBackdrop = () => {
		setOpenBackdrop(true);
	};

	const handleSalirVerComo = () => {
		setContextValue({
			...contextValue,
			sesionVerComo: false,
			dataAdministradorSesionVerComo: {},
			usuarioLogeado: contextValue.dataAdministradorSesionVerComo,
		});

		handleChangeComponent(multiViewComponentsPaths.panelAdministracion);
	};

	const handleSeleccionNotificacion = async ({ currentTarget }) => {
		const { id } = currentTarget;

		const { codigoNotificacion, idNotificacionUsuario, leida } = notificacionesUsuario.find((notificacion) => notificacion.idNotificacionUsuario === parseInt(id));

		let componentPath = "";

		switch (codigoNotificacion) {
			case typesCodigosNotificacionUsuario.certificacionMos:
				componentPath = multiViewComponentsPaths.cursoPropedeuticoMos;

				break;

			case typesCodigosNotificacionUsuario.claseEnVivo:
			case typesCodigosNotificacionUsuario.nuevaClaseVirtual:
				componentPath = multiViewComponentsPaths.aulaVirtual;

				break;

			case typesCodigosNotificacionUsuario.nuevaRespuestaTemaForoAyudaAutor:
			case typesCodigosNotificacionUsuario.nuevaRespuestaTemaForoAyudaSeguidores:
			case typesCodigosNotificacionUsuario.nuevoPostForoAyuda:
				componentPath = multiViewComponentsPaths.foroAyuda;

				break;

			case typesCodigosNotificacionUsuario.nuevoCurso:
				componentPath = multiViewComponentsPaths.misCursos;

				break;

			case typesCodigosNotificacionUsuario.nuevoPostForoEmpresarial:
				componentPath = multiViewComponentsPaths.foroEmpresarial;

				break;

			case typesCodigosNotificacionUsuario.registroSegundoUsuario:
				await ModalSweetAlert({
					title: "¡Usuario secundario registrado!",
					icon: "success",
					text: "Tu segundo usuario ha sido dado de alta exitosamente. Para que pueda acceder a la plataforma, compártele tus datos de acceso.",
				});

				break;

			default:
				componentPath = multiViewComponentsPaths.home;
				break;
		}

		if (!leida) {
			await updateNotificacionUsuario(idNotificacionUsuario);
		}

		if (componentPath !== "") {
			handleChangeComponent(componentPath);
		}
	};

	const handleEnviarCorreoRestablecimientoContrasenia = async () => {
		try {
			handleOpenBackdrop();

			const urlFormulario = `${window.location.origin}${dashboardRoutesPaths.formularioRecuperacionContrasenia}`;

			await enviarFormularioRecuperacionContrasenia({
				correo: usuarioLogeado.correo,
				nombre: usuarioLogeado.nombreCompleto,
				urlFormulario,
				idCredencial: usuarioLogeado.idCredencial,
			});

			handleCloseBackdrop();

			NotificationManager.success("Se ha enviado un enlace a tu correo, ingresa para cambiar tu contraseña.");
		} catch (error) {
			console.log("error al enviar el correo de restablecimiento de contraseña: ", error);
		}
	};

	const handleEnviarCorreoRestablecimientoNombreUsuario = async () => {
		try {
			handleOpenBackdrop();

			const urlFormulario = `${window.location.origin}${dashboardRoutesPaths.formularioRecuperacionNombreUsuario}`;

			await solicitudRestablecimientoNombreUsuario({
				correo: usuarioLogeado.correo,
				nombre: usuarioLogeado.nombreCompleto,
				urlFormulario,
				idCredencial: usuarioLogeado.idCredencial,
			});

			handleCloseBackdrop();

			NotificationManager.success("Se ha enviado un enlace a tu correo, ingresa para cambiar tu nombre de usuario.");
		} catch (error) {
			console.log('error al enviar el restablecimiento de nombre de usuario: ', error);	
		}
	};

	useEffect(() => {
		const consultarNotificacionesUsuario = async () => {
			try {
				const notificaciones = await getNotificacionesUsuario(idPerfil);

				setNotificacionesUsuario(notificaciones.reverse());

				setContextValue({
					...contextValue,
					notificacionesUsuario: notificaciones,
				});
			} catch (error) {
				// console.log("Error al consultar las notificaciones del usuario: ", error);
			}
		};

		consultarNotificacionesUsuario();
	}, []);

	useEffect(() => {
		if (notificacionesUsuario.length > 0) {
			const noLeidas = notificacionesUsuario.filter((notificacion) => notificacion.leida === 0);

			setNotificacionesNoLeidas(noLeidas);

			setCantidadNotificacionesNoLeidas(noLeidas.length);
		}
	}, [notificacionesUsuario]);

	return (
		<Navbar className="px-3">
			<Nav>
				{usuarioLogeado.rol !== typesTiposUsuario.invitado && usuarioLogeado.rol !== typesTiposUsuario.lecturaVeloz && usuarioLogeado.rol !== typesTiposUsuario.cortesia && width > 768 && (
					<>
						<div className="d-flex align-items-center mx-3">
						<p onClick={(e) => op.current.toggle(e)} className="btn-notificaciones pointer m-2">
							<i className="pi pi-bell p-overlay-badge" style={{ fontSize: "2rem" }}>
								{cantidadNotificacionesNoLeidas !== 0 && <Badge value={cantidadNotificacionesNoLeidas} className="icon-notificaciones" severity="danger"></Badge>}
							</i>
						</p>


						<OverlayPanel className="contenedor-notificaciones" ref={op}>
							<Container fluid>
								{notificacionesUsuario.length > 0 ? (
									<>
										{notificacionesUsuario.map(
											(notificacion, idx) =>
												!notificacion.leida && (
													<Row
														id={notificacion.idNotificacionUsuario}
														key={idx}
														className={`notificacion-usuario pointer radius-notificacion pt-3  ${notificacion.leida ? "leida" : "no-leida"}`}
														onClick={handleSeleccionNotificacion}>
														<Col xs={!notificacion.leida ? 11 : 12}>
															<h5>{notificacion.titulo}</h5>

															<p>{notificacion.descripcion}</p>
														</Col>

														{!notificacion.leida && (
															<Col xs={1} className="d-flex align-items-center justify-content-center">
																<Badge severity="info" className="icon-no-leidas"></Badge>
															</Col>
														)}
													</Row>
												)
										)}
									</>
								) : (
									<Row>
										<Col>
											<h3>No tienes notificaciones</h3>
										</Col>
									</Row>
								)}
							</Container>
						</OverlayPanel>
							<OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-top`}>Pomodoro</Tooltip>}>
								<h2>
									{" "}
									<FaRegClock
										color="grey"
										className="pointer"
										onClick={() => {
											handleChangeComponent("pomodoro");
										}}
									/>{" "}
								</h2>
							</OverlayTrigger>

							<ThemeSwitcher handleChangeComponent={handleChangeComponent} />
						</div>
					</>
				)}

				<div className="d-flex align-items-center">
					<img className="avatar" src={avatarPerfil !== null && avatarPerfil !== "" ? avatarPerfil : require("@assets/images/avatar.png")} alt="Foto de perfil" />

					<NavDropdown
						title={
							width < 768 ? (
								<span>{nombreCompleto}</span>
							) : (
								<>
									{sesionVerComo ? (
										<>
											<span>{nombreCompletoAdmin + " " + apellidosAdmin}</span>
											<br />
											<span>Iniciado como: {nombreCompleto + " " + apellidos}</span>
										</>
									) : (
										<span>{nombreCompleto + " " + apellidos}</span>
									)}
								</>
							)
						}
						className="mx-2 dropdown-user"
						align="right">
						<NavDropdown.Item onClick={handleProfile}>
							<h6>Configuración</h6>
						</NavDropdown.Item>

						{width < 768 && (
							<>
								<NavDropdown.Item>
									<p onClick={(e) => op.current.toggle(e)} className=" pointer">
										<h6 className="p-overlay-badge">
											Notificaciones {cantidadNotificacionesNoLeidas !== 0 && <Badge value={cantidadNotificacionesNoLeidas} className="icon-notificaciones" severity="danger"></Badge>}
										</h6>
									</p>
								</NavDropdown.Item>

								<NavDropdown.Item
									onClick={() => {
										handleChangeComponent("pomodoro");
									}}>
									<h6> Técnica pomodoro </h6>
								</NavDropdown.Item>
							</>
						)}

						{rol === typesTiposUsuario.estudiante ? (
							usuarioAdicional ? (
								<>
									{segundoPerfilRegistrado ? (
										<NavDropdown.Item onClick={handleProfileSelection}>
											<h6>Cambiar de usuario</h6>
										</NavDropdown.Item>
									) : (
										<NavDropdown.Item onClick={handleRegisterSecondaryUser}>
											<h6>Registrar 2° usuario</h6>
										</NavDropdown.Item>
									)}
								</>
							) : null
						) : null}

						{(usuarioTitular && !sesionVerComo) ? (
							<>
								<NavDropdown.Item onClick={handleEnviarCorreoRestablecimientoContrasenia}>
									<h6>Cambiar contraseña</h6>
								</NavDropdown.Item>

								<NavDropdown.Item onClick={handleEnviarCorreoRestablecimientoNombreUsuario}>
									<h6>Cambiar nombre de usuario</h6>
								</NavDropdown.Item>
							
							</>
						) : null}

						{sesionVerComo && (
							<NavDropdown.Item onClick={handleSalirVerComo}>
								<h6>Cerrar sesión Ver Como</h6>
							</NavDropdown.Item>
						)} 

						{!sesionVerComo && (
							<NavDropdown.Item onClick={handleLogout}>
								<h6>Cerrar sesión</h6>
							</NavDropdown.Item>
						)}
					</NavDropdown>
				</div>
			</Nav>

			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop} onClick={handleCloseBackdrop}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Navbar>
	);
};

export default DatosUsuario;
