
const columnsUsuariosAppMovil = [
    {
        header: "Nombre Completo",
        accessorKey: "nombreCompleto",
        sortable: true,
        center: true,
        compact: true,
        width: "200px",
    },
    {
        header: "Apellidos",
        accessorKey: "apellidos",
        sortable: true,
        center: true,
        compact: true,
        width: "40px",
    },
    {
        header: "Nombre de usuario",
        accessorKey: "nombreUsuario",
        sortable: true,
        center: true,
        compact: true,
        width: "360px",
    },
    {
        header: "CURP",
        accessorKey: "curp",
        sortable: true,
        center: true,
        compact: true,
        width: "350px",
        
    },
    {
        header: "Correo",
        accessorKey: "correo",
        sortable: true,
        center: true,
        compact: true,
        width: "100px",
       
    },
];

export default columnsUsuariosAppMovil;
