import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

const ContenidoPlataforma = () => {
  return (
    <Row>
        <Col>
            <TitleDashboard title={"Hay problemas para poner un atajo en las lecciones"}/>

            <p className="my-3">
               En casos donde, en una lección, el usuario tenga problemas para usar un atajo como <code>ctrl + E</code> es necesario seguir los siguientes pasos:
               <ul>
                    <li className="mb-3">Instalar dentro del archivo <b>index.html</b> de la lección de Captivate el jquery.hotkeys.js dentro de la variable <b>lJSFiles</b>:
                        <Image className='my-3' fluid src={require("@assets/images/hotkeys.png")} />
                    </li>
                    <li className="mb-3">
                        Crear un archivo llamado <b>cusstom_events.js</b> donde se insertará el siguiente código para la combinación deseada:
                        <Image className='my-3' fluid src={require("@assets/images/custom_event.png")} />

                    </li>
               </ul>
            </p>
            
            
            
        </Col>
    </Row>
  )
}

export default ContenidoPlataforma